import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import Navigation from '../Navigation';
import StyleDrawer from '../StyleDrawer';
import WebpImage from '../WebpImage';

const Header = () => {
  // var isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <>
      <Box
        as="header"
        pos="relative"
        background="linear-gradient(270deg, #000000 65%, #2D1412 100%)"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Container py={{ base: 2, lg: 4 }} px={4} maxW={'1920px'}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            // minH={{ lg: "128px" }}
          >
            <Box flexShrink={0}>
              <NavLink to="/">
                <WebpImage
                  isPNG
                  imgfile="logo-nz-brochure"
                  boxSize={{ base: '56px', md: '88px', '2xl': '96px' }}
                />
              </NavLink>
            </Box>
            <Navigation display={{ base: 'none','2xl': 'block' }} />
            {/* <Heading
              as="h3"
              textTransform={"capitalize"}
              size="md"
              color={"whiteAlpha.900"}
              display={{ base: "none", lg: "block" }}
            >
              {process.env.REACT_APP_TITLE}
              <span>we distribute visitor information</span>
            </Heading> */}
            <StyleDrawer />
          </Flex>
        </Container>
      </Box>
      <Navigation display={{ base: 'none', lg: 'block', '2xl': 'none' }} />
    </>
  );
};

export default Header;
