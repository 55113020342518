export const formatlink = (text: String) => {
  if (text.includes(",")) {
    text = text.replaceAll(",", "");
  }
  if (text.includes("/")) {
    text = text.replaceAll("/", "-");
  }
  if (text.includes("&")) {
    text = text.replaceAll("&", "and");
  }
  return text.split(" ").join("-").toLowerCase();
};
