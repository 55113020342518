import * as Yup from 'yup';
const NZMobileRegExp = /^02[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$/;

const CaptureSchema = Yup.object().shape({
  from_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required'),
  from_email: Yup.string()
    .email('Please enter a valid email')
    .required('Email address is required'),
  mobilenumber: Yup.string()
    .min(7, 'Too Short!')
    .max(16, 'Too Long!')
    .required('Please enter a valid phone number'),
  message: Yup.string()
    .min(2, 'Too Short!')
    .required('Your message is required'),
});

export const orderCaptureSchema = Yup.object().shape({
  from_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required'),
  from_email: Yup.string()
    .email('Please enter a valid email')
    .required('Email address is required'),
  mobilenumber: Yup.string()
    .min(7, 'Too Short!')
    .max(16, 'Too Long!')
    .required('Please enter a valid mobile number')
    .matches(NZMobileRegExp, 'Phone number is not valid'),
  address: Yup.string()
    .min(4, 'Too Short!')
    .required('Please enter a valid address'),
  region: Yup.string().required('Please select a region'),
  postcode: Yup.string()
    .min(3, 'Too Short!')
    .max(8, 'Too Long!')
    .required('Please enter a valid postcode'),
});

export default CaptureSchema;
