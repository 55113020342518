import { BlogImage } from 'components/BlogImage';
import MetaHelmet from 'components/MetaHelmet';
import { H3, Paragraph as P } from 'theme/ui-components/Typography';
import { Staff } from 'components/Staff';
import InnerPageLayout from './InnerPageLayout';

export const AboutUs = () => (
  <>
    <MetaHelmet
      title="About Us"
      description="Discover New Zealand Brochures & Distribution - Your Trusted Partner in Tourism Materials Distribution for Over 20 Years. Explore Our Services Today!"
      canonicalUri='about-us'
    />
    <InnerPageLayout
      title="About Us"
      imagefile="_DSC5447.jpg"
      introText="We are passionate about tourism!"
    >
      <P>
        New Zealand Brochures &amp; Distribution is a privately owned and
        operated NZ company that has been trading in brochure distribution for
        over 20 years.
      </P>
      <P>
        On 1st October, 2016 we merged our Carlton Tourism Promotions and Matuka
        Brochure Distribution businesses together under a single brand as we
        look to expand our business services throughout New Zealand.
      </P>
      <P>
        We focus on physically distributing all tourism material and brochures
        throughout New Zealand and providing the very best located and highest
        traffic flow brochure display units.
      </P>
      <P>
        Also we offer an online portal to find and order a range of
        tourism-related products.
      </P>
      <P>
        You will certainly find us in the reception areas of some of the busiest
        hotels and motels in the South Island and in camp grounds, rental car
        and campervan reception areas, backpackers and airports around the
        regions.
      </P>
      <P>
        Our point of difference is our personal and friendly service and our
        willingness to distribute posters, discount vouchers, event information,
        books &amp; magazines, and all tourism related information. We don't
        simply just distribute brochures!
      </P>
      <BlogImage filename="team-group" />
      <H3>We are passionate about tourism!</H3>
      <P>
        We also now have an online portal where you can access local services
        and suppliers that are Essential to visitors when travelling around the
        South Island no matter where you are or what you are doing!
      </P>
      <P>
        Need a doctor or an emergency dentist or simply need to know where to
        have to have a pedicure or to find the local pharmacy?! Look no further
        than nzbrochures.com — we can certainly help you!
      </P>
      <H3>Contact Us</H3>
      <Staff />
    </InnerPageLayout>
  </>
);
