import { Button } from '@chakra-ui/react';
import { MinusIcon, PlusIcon } from 'theme/ui-components/icons';

interface ButtonHolderProps {
  // children?: React.ReactNode;
  size?: string;
  icon: 'plus' | 'minus';
  /**
   * Optional click handler
   */
  onClick?: () => void;
}
export const QuantityControlButton = ({ onClick, icon }: ButtonHolderProps) => {
  const ButtonIcon = () => {
    switch (icon) {
      case 'plus':
        return <PlusIcon boxSize={5} data-testid="plus-icon" />;
      case 'minus':
        return <MinusIcon boxSize={5} data-testid="minus-icon" />;
      default:
        return <PlusIcon boxSize={5} />;
    }
  };

  return (
    <Button
      boxSize={10}
      px={0}
      py={0}
      variant="outline"
      borderRadius="full"
      borderColor="gray.50"
      colorScheme={'grey'}
      borderWidth="1px"
      onClick={onClick}
      data-testid="quantity-control-button"
    >
      <ButtonIcon />
    </Button>
  );
};
