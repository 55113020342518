export const breakpoints = {
  small: "640px",
  medium: "768px",
  large: "1024px",
};

export const misc = {
  unit: 12,
  baseline: "24px",
  animationDuration: "0.3s",
  transitionAnimate: "all 0.33s"
};

export default misc;
