const SITE_CONFIG = {
  SITE_NAME: 'New Zealand Brochures & Distribution',
  SITE_SHORT_NAME: 'NZ Brochures',
  SITE_URL: 'https://nzbrochures.com/',
  SITE_DESCRIPTION:
    'New Zealand Brochures & Distribution visitor information, tourism and activities brochures for the South Island of New Zealand',
  SITE_ROOT_PATH: '/',
  CONTACT_NUMBER: process.env.REACT_APP_NUMBER,
  EMAIL: 'richard@nzbrochures.com',
  ARCHIEWEB: {
    name: 'ArchieWeb',
    url: 'https://archieweb.nz',
    service: 'web design and development',
  },
  CANCELATION_PERIOD: '6 months',
  RECAPTCHA_SITE_KEY: '6LckbbcfAAAAAG_fVBdWh6v-9OGGnfgmLWuR1YeZ',
  RECAPTCHA_SECRET_KEY: '6LckbbcfAAAAAN5ozCFInGeqba_pfm05I17EkuoD',
  RECAPTCHA_TEST_SITE_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  RECAPTCHA_TEST_SECRET_KEY: '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe',
  EMAILJS_serviceID: 'service_id_emailjs',
  EMAILJS_templateID: 'template_id_emailjs',
  EMAILJS_orderForm_templateID: 'template_39jezha',
  // where it says API Keys
  EMAILJS_userID: 'WJI6XvaQN_xWp9diP',
  EMAILJS_BCC: 'test@archieweb.nz',
  EMAILJS_TO_EMAIL: 'richardbenton@me.com',
  ERROR_SUBMIT_MESSAGE:
    'There was an issue with the submission, please try again or call us: ',
  SUBMITTED_MESSAGE:
    'Thanks for submitting the form! We really appreciate your interest and we will get back to you as soon as possible. If you have any questions or concerns, please let us know',
};

export default SITE_CONFIG;
