import { Box, Link, ListItem, OrderedList, Stack } from '@chakra-ui/react';
import uuid from 'react-uuid';
import { BlogImage } from '../components/BlogImage';
import SITE_CONFIG from '../siteConfig';
import { Paragraph } from '../theme/ui-components/Typography';
import { InternalLink } from './DisplayUnit/RouteLink';
import LOOP from './DisplayUnit/advantages.json';
import InnerPageLayout from './InnerPageLayout';

export const RequestUnit = () => {
  return (
    <InnerPageLayout
      title="Request a brochures display unit"
      metaDescrib="Request Brochure Displays - Enhance Visitor Experience and Boost Earnings. Install Our Brochure Units for Free!"
      canonicalUri="request-brochure-display-unit"
    >
      <Stack spacing={5}>
        <Paragraph>
          {SITE_CONFIG.SITE_NAME} installs and maintain brochures display units
          in most key locations in the South Island of New Zealand for the
          convenience of tourists and travellers. They vary in range and size
          depending on the space available.
        </Paragraph>
        <Paragraph>
          The Brochure Display Units have the following advantages to the site
          owner
        </Paragraph>

        <Box>
          <OrderedList spacing={2}>
            {LOOP.map((data: any) => (
              <ListItem key={uuid()}>
                <Paragraph
                  as="span"
                  fontWeight="600"
                  backgroundImage="linear-gradient(0deg, rgba(0,255,188, 0.12) 35%, transparent 65%)"
                >
                  {data}
                </Paragraph>
              </ListItem>
            ))}
          </OrderedList>
        </Box>

        <BlogImage filename="brochure-display-unit" />
        <Paragraph>
          You can view the current list of{' '}
          <InternalLink path="/stand-locations">
            all of our locations
          </InternalLink>
          .
        </Paragraph>
        <Paragraph>
          To restock brochures, you can call our friendly{' '}
          <Link href="/#team">team</Link> for a personal visit.
        </Paragraph>
        <Paragraph>
          Please <InternalLink path="contact">contact us</InternalLink> if you
          are interested in hosting a NZBrochures display unit at your premises.
        </Paragraph>
        <BlogImage filename="brochure_display_unit" />
      </Stack>
    </InnerPageLayout>
  );
};
