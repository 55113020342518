import {
  Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputGroup, InputProps, InputRightElement, Select,
  SelectProps, useColorModeValue
} from "@chakra-ui/react";
import { ErrorMessage, useField, useFormik } from "formik";
import React, { ChangeEvent, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CalendarIcon, ChevronIcon } from "../theme/ui-components/icons";

export type FormElementProps = InputProps & SelectProps;

interface Props extends FormElementProps {
  name: string;
  label?: string;
  helpText?: string;
  hasIcon?: React.ReactNode;
  inputType?: string;
  children?: React.ReactNode;
}

const InputElementControl = ({
  label,
  helpText,
  hasIcon,
  children,
  inputType,
  ...props
}: Props) => {
  const [isFocused, setFocused] = useState(false);
  const [hasValue, setValue] = useState("");
  const onFocus = () => setFocused(!isFocused);
  const onBlur = () => setFocused(false);
  const [field, meta] = useField(props.name);
  const errorTouched = meta.error && meta.touched;
  const toggleClass =
    isFocused || hasValue || props.type === "date" ? "active" : "";
  const handleChange = (el: any) => setValue(el.target.value);
  const arialabeltext = props.name + ": " + hasValue;

  /* Formik stuffs */
  const validate = () => {
    return undefined;
  };
  const onSubmit = () => {
    return undefined;
  };

  const initialValues = {
    mobilenumber: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const reverseColor = useColorModeValue("black.300", "white");
  /* switch the input component depends on the inputType */
  const inputTypeControl = (checkInputType: any) => {
    switch (checkInputType) {
      case "select":
        return (
          <Select
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue=""
            onChange={handleChange}
            className={toggleClass}
            aria-label={arialabeltext}
            icon={<ChevronIcon transform="rotate(90deg)" />}
            {...props}
            sx={{
              "&.active": {
                pt: 3,
              },
            }}
          >
            <Box as="option" value="" disabled display="none" />
            {children}
          </Select>
        );
      case "mobilenumber":
        return (
          <InputGroup
            sx={{
              ".PhoneInput": {
                backgroundColor: "white",
                height: "60px",
                width: "100%",
                pl: 4,
                borderRadius: "md",
                border: "3px solid",
                borderColor: errorTouched ? "red.400" : "black.400",
                "&Input": {
                  boxSize: "100%",
                  px: "0.5rem",
                  pt: "1rem",
                  backgroundColor: "transparent",
                  borderRadius: "md",
                  "&:focus-visible, &:focus": {
                    border: "none",
                    outline: 0,
                    borderImageWidth: 0,
                  },
                },
                "&Country, &CountrySelect": {
                  backgroundColor: "transparent",
                },
                "&CountrySelectArrow": {
                  opacity: 1,
                },
                "&CountryIcon--border": {
                  boxShadow: "none",
                },
              },
            }}
          >
            <PhoneInput
              defaultCountry="NZ"
              international
              onChange={(e: ChangeEvent) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                formik.setFieldValue(props.name, e), handleChange;
              }}
              onBlur={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                formik.handleBlur(props.name), onBlur;
              }}
              onFocus={onFocus}
              // value={hasValue}
              value={formik.values.mobilenumber}
              aria-label={arialabeltext}
              /* can't use isRequired as it's not a Chakra input */
              required
              {...props}
            />
          </InputGroup>
        );
      default:
        return (
          <InputGroup>
            <Input
              height="60px"
              px="1rem"
              pt="1rem"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={handleChange}
              className={toggleClass}
              aria-label={arialabeltext}
              {...props}
              sx={{
                "&::-webkit-calendar-picker-indicator": {
                  display: "none",
                },
              }}
            />
            {hasIcon && (
              <InputRightElement>
                {props.type === "date" ? <CalendarIcon boxSize={6} /> : hasIcon}
              </InputRightElement>
            )}
          </InputGroup>
        );
    }
  };

  return (
    <FormControl
      isInvalid={errorTouched ? true : false}
      isRequired={props.isRequired}
      position="relative"
      lineHeight="none"
      overflow="hidden"
      mt={0}
      _hover={{
        "label:not(.active)": {
          color: "black.500",
        },
      }}
      {...field}
    >
      {inputTypeControl(inputType)}
      <FormLabel
        color={reverseColor}
        position="absolute"
        top={inputType === "mobilenumber" ? "12px" : "22px"}
        left={inputType === "mobilenumber" ? "68px" : "1.25rem"}
        fontSize={inputType === "mobilenumber" ? "0.75rem" : "inherit"}
        m={0}
        cursor="text"
        transition="all 0.3s"
        className={toggleClass}
        sx={{
          pointerEvents: "none",
          "&.active": {
            top: inputType !== "mobilenumber" && "12px",
            left: inputType !== "mobilenumber" && "18px",
            fontSize: "0.75rem",
            color: "surfaceBlue",
            "&[data-invalid]": {
              color: "red.400",
            },
          },
        }}
      >
        {label && label.charAt(0).toUpperCase() + label.slice(1)}
      </FormLabel>
      <FormErrorMessage pl={4}>
        <ErrorMessage name={props.name} />
      </FormErrorMessage>
      {!hasValue && !errorTouched && helpText && (
        <FormHelperText as="span" fontSize="sm" pl={4} display="inline-block">
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default InputElementControl;
