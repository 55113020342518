import React from 'react';
import { Image, ImageProps } from '@chakra-ui/react';
import SITE_CONFIG from '../../siteConfig';

export interface WebpProps extends ImageProps {
  imgfile: string;
  altText?: string;
  isPNG?: boolean;
}

const WebpImage = ({ imgfile, isPNG, altText, ...props }: WebpProps) => {
  return (
    <picture>
      <source
        srcSet={`/images/${imgfile}${isPNG ? '_lossless.webp' : '.webp'}`}
        type="image/webp"
      />
      <Image
        src={`/images/${imgfile}${isPNG ? '.png' : '.jpg'}`}
        alt={altText ? altText : SITE_CONFIG.SITE_NAME}
        {...props}
      />
    </picture>
  );
};

export default WebpImage;
