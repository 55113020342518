import { Button } from '@chakra-ui/react';
import PrintIcon from './icons/PrintIcon';

const PrintButton = () => (
  <Button
    // variant="ghost"
    size="sm"
    fontSize="14px"
    p={{ lg: 5 }}
    borderRadius="full"
    display="inline-flex"
    gap={2}
    alignItems="center"
    aria-label="print this page"
    onClick={() => window.print()}
    flexShrink={0}
    sx={{
      '@media print': {
        display: 'none',
      },
    }}
  >
    <PrintIcon boxSize={6} />
    Print this page
  </Button>
);

export default PrintButton;
