import { Box, HStack, Image, SimpleGrid } from '@chakra-ui/react';
import { Fragment } from 'react';
import uuid from 'react-uuid';
import BrochureItem from '../../../components/Brochures/BrochureItem';
import BROCHURES from '../../../components/Brochures/brochuresList';

const SHELVES = ['rack1', 'rack2'];

const WoodShelf = () => (
  <HStack spacing={0} transform="translateY(-10px)" pos={'relative'} zIndex={1}>
    <Image src="/images/shelf_left.png" alt="" role="presentation" />
    <Box
      w="100%"
      h={'52px'}
      bgImage="url('/images/shelf_center.webp'), url('/images/shelf_center.png')"
    >
      &nbsp;
    </Box>
    <Image src="/images/shelf_right.png" role="presentation" alt="" />
  </HStack>
);

export const DisplayShelves = () => (
  <>
    {SHELVES.map((shelf) => {
      const shelfBrochures = BROCHURES.filter((item: any) => item[shelf]);
      // console.log(shelfBrochures.length);

      return (
        <Fragment key={uuid()}>
          <SimpleGrid
            columns={[1, 2, 3, 4, 5]}
            px={{ sm: 10, lg: 20 }}
            mb={1}
            pos={'relative'}
            zIndex={2}
            spacing={6}
            sx={{
              '.bookshelf:first-of-type': {
                display: { base: 'unset' },
              },
              '.bookshelf:nth-of-type(-n+2)': {
                display: { sm: 'unset' },
              },
              '.bookshelf:nth-of-type(-n+3)': {
                display: { md: 'unset' },
              },
              '.bookshelf:nth-of-type(-n+4)': {
                display: { lg: 'unset' },
              },
              '.bookshelf:nth-of-type(-n+5)': {
                display: { xl: 'unset' },
              },
            }}
            data-testid="display-shelves"
          >
            {shelfBrochures.map((item: any) => (
              <BrochureItem
                key={uuid()}
                dataObject={item}
                className="bookshelf"
                display="none"
                hideButton
              />
            ))}
          </SimpleGrid>
          <WoodShelf />
        </Fragment>
      );
    })}
  </>
);
