import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';

/* 重新整理頁面時回到最頂 */
// const ScrollToTop = () => {
//   const { pathname } = useLocation();
//   React.useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);
//   return null;
// };

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Router>
        {/* <ScrollToTop /> */}
        {/* Then render it at the top of your app, but below Router */}
        <App />
      </Router>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
