import { createIcon } from '@chakra-ui/react';
export const BagIcon = createIcon({
    displayName: 'BagIcon',
    viewBox: '0 0 30 30',
    path: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
            <path d="M27.7 10.4c-.5-.5-1.2-.8-1.9-.8h-3.2V8.4c0-2-.8-3.9-2.2-5.3C18.9 1.6 17 .8 15 .8c-2 0-3.9.8-5.3 2.2-1.5 1.4-2.2 3.3-2.2 5.4v1.2H4.3c-.4 0-.7.1-1.1.2-.3.1-.6.3-.9.6-.3.3-.5.6-.6.9-.1.3-.2.7-.2 1.1l1 14.4c.1.7.4 1.3.9 1.7.5.4 1.1.7 1.8.7h19.5c.7 0 1.3-.2 1.8-.7.5-.4.8-1 .9-1.8l1.2-14.3v-.1c-.1-.7-.4-1.4-.9-1.9zm-17.2-2c0-1.2.5-2.4 1.3-3.2.9-.9 2-1.3 3.2-1.3 1.2 0 2.4.5 3.2 1.3.9.9 1.3 2 1.3 3.2v1.2h-9.1V8.4zm13.9 17.8h-19l-.9-13.6h2.9V15c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-2.4h9.1V15c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-2.4h2.9l-1 13.6z" />
        </svg>
    ),
});

export default BagIcon;
