import {
  Box,
  Center,
  Container,
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import uuid from 'react-uuid';
import { formatlink } from '../../helpers';
import SITE_CONFIG from '../../siteConfig';
import { NaviItemTypes } from '../Navigation/Navigation';
import REGIONS from '../Navigation/navitems.json';
import LOOOP from './footerlinks.json';

interface FooterItemProps {
  heading: string;
  // items: any;
  items: NaviItemTypes[];
  isExternal?: boolean;
  viewAllLink?: boolean;
}

const GridListItem = ({
  heading,
  items,
  viewAllLink,
  isExternal,
}: FooterItemProps) => (
  <GridItem>
    <Heading
      as="h3"
      fontSize="md"
      letterSpacing={'1.33px'}
      color="red.400"
      mb={4}
      textTransform="uppercase"
    >
      {heading}
    </Heading>
    <Stack spacing={1} textTransform="capitalize">
      {items.map((data) => {
        const formatUri = data.uri ? data.uri : formatlink(data.title);
        return (
          <Fragment key={uuid()}>
            {isExternal ? (
              'TODO'
            ) : (
              <NavLink to={formatUri}>{data.title}</NavLink>
            )}
          </Fragment>
        );
      })}
      {/* {viewAllLink && <NavLink to="all">All Brochures</NavLink>} */}
    </Stack>
  </GridItem>
);

const Footer = () => (
  <Box
    as="footer"
    color="whiteAlpha.900"
    bgColor="black.400"
    bg="linear-gradient(225deg, #000000 50%, #2D1412 100%)"
    sx={{
      '@media print': {
        display: 'none',
      },
    }}
  >
    <Container>
      <SimpleGrid columns={[1, 2, 2, 4]} rowGap={12} columnGap={6}>
        {/* <GridItem>
            <NavLink to="/">
              <Image
                src="images/logo-nz-brochure.png"
                boxSize={{ base: "72px", md: "144px" }}
                // position={"absolute"}
                alt={SITE_CONFIG.SITE_NAME}
              />
            </NavLink>
          </GridItem> */}
        <GridListItem
          heading="Online Brochures"
          items={REGIONS.REGIONS}
          viewAllLink
        />
        <GridListItem heading="opportunities" items={LOOOP.opportunities} />
        <GridListItem heading="more" items={LOOOP.more} />
      </SimpleGrid>
    </Container>
    <Center borderTop="2px solid red" boxSizing="border-box" py={[3, 4, 6]}>
      <Text size="sm" color="grey.200" mb="2px">
        Copyright &copy; Alpine View Ltd. {new Date().getFullYear()} | website
        by{' '}
        <a
          href={SITE_CONFIG.ARCHIEWEB.url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`${SITE_CONFIG.ARCHIEWEB.name} - ${SITE_CONFIG.ARCHIEWEB.service}`}
        >
          {SITE_CONFIG.ARCHIEWEB.name}
        </a>
      </Text>
    </Center>
  </Box>
);

export default Footer;
