import { createIcon } from "@chakra-ui/react";
import React from "react";

export const InfoIcon = createIcon({
  displayName: "InfoIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <circle cx="15" cy="15" r="10" strokeWidth="1px" />
      <path
        fill="currentColor"
        d="M14,12.4H16v-1.7H14V12.4z M14,19.4h2v-6.1h-2V19.4z"
      />
    </>
  ),
});

export default InfoIcon;
