import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
// import { InternalLink } from 'pages/DisplayUnit/RouteLink';
import { BagIcon } from 'theme/ui-components/icons';
import { Link } from 'react-router-dom';

type CheckoutButtonProps = {
  quantities: ReactNode;
};

export const CheckoutButton = ({ quantities }: CheckoutButtonProps) => (
  <Link
    to={{
      pathname: '/cart',
    }}
    style={{
      position: 'fixed',
      textDecoration: 'none',
      zIndex: 99,
      right: '24px',
      top: 'calc(50% - 32px)',
      width: '64px',
      height: 'auto',
    }}
    data-testid="checkout-button"
    
  >
    <Flex
      boxSize={16}
      pos="relative"
      justifyContent="center"
      alignItems="center"
      bgColor="white"
      _hover={{ bgColor: 'grey.50' }}
      transition="background-color 0.2s ease-in-out"
      borderRadius="full"
      boxShadow="0px 3px 4px rgba(0, 0, 0, 0.1)"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <BagIcon boxSize={6} />
      <Flex
        p={4}
        bgColor="red.400"
        color="white"
        boxSize={6}
        borderRadius="full"
        justifyContent="center"
        alignItems="center"
        textDecor="none"
        fontSize="xs"
        fontWeight="600"
        zIndex={100}
        position="absolute"
        top={-2}
        right={-2}
      >
        {quantities}
      </Flex>
    </Flex>
  </Link>
);
