import { mode } from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

const baseStyle = {
    lineHeight: 'none',
    borderRadius: 'md',
    borderColor: 'transparent',
    _focus: {
        boxShadow: 'outline',
    },
    _disabled: {
        opacity: 0.5,
    },
};

function variantGhost(props: Dict) {
    const { colorScheme: c } = props;

    return {
        color: mode(`${c}.600`, `${c}.200`)(props),
        bg: 'transparent',
        _hover: {
            color: 'white',
            bg: mode(`${c}.500`, `${c}.400`)(props),
            _disabled: {
                color: mode(`${c}.500`, `${c}.400`)(props),
                bg: 'inherit',
            },
        },
        _active: {
            color: 'white',
            bg: mode(`${c}.600`, `${c}.500`)(props),
        },
    };
}

function variantOutline(props: Dict) {
    const { colorScheme: c } = props;
    return {
        ...variantGhost(props),
        borderWidth: '2px',
        _hover: {
            color: 'white',
            borderColor: `${c}.500`,
            bg: mode(`${c}.500`, `${c}.600`)(props),
            _disabled: {
                color: mode(`${c}.500`, `${c}.600`)(props),
                bg: 'inherit',
            },
        },
        _active: {
            color: 'white',
            bg: mode(`${c}.500`, `${c}.600`)(props),
        },
    };
}

function variantSolid(props: Dict) {
    const { colorScheme: c } = props;
    return {
        borderWidth: '2px',
        _active: { bg: mode(`${c}.600`, `${c}.500`)(props) },
    };
}

function variantLink(props: Dict) {
    const { colorScheme: c } = props;
    return {
        _hover: {
            color: mode(`${c}.600`, `${c}.200`)(props),
            textDecoration: 'none',
        },
        _active: {
            color: mode(`${c}.600`, `${c}.200`)(props),
        },
        _focus: {
            boxShadow: 0,
        },
    };
}

function variantWhite() {
    return {
        color: 'white',
        bg: 'transparent',
        border: '2px solid white',
        _hover: {
            bg: 'white',
            color: 'black',
            _disabled: {
                bg: 'white',
                color: 'black',
            },
        },
        _disabled: {
            bg: 'white',
            color: 'black',
        },
    };
}

const variants = {
    ghost: variantGhost,
    outline: variantOutline,
    solid: variantSolid,
    link: variantLink,
    monochrome: variantWhite,
};

const sizes = {
    md: {
        fontSize: 'md',
        height: 'auto',
        minW: 10,
        py: { base: '0.75rem', lg: '1rem' },
        px: { base: '1rem', lg: '1.625rem' },
        lineHeight: 'none',
    },
};

const defaultProps = {
    variant: 'solid',
    size: 'md',
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    baseStyle,
    variants,
    sizes,
    defaultProps,
};
