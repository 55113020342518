import React from 'react';
import uuid from 'react-uuid';
import { Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { H3, Paragraph as P } from '../theme/ui-components/Typography';
import InnerPageLayout from './InnerPageLayout';
import SITE_CONFIG from '../siteConfig';

const NZBD = SITE_CONFIG.SITE_NAME;
const CONDITION = [
  'To change out or distribute new brochures with take up to 4 weeks from time of arrival at our brochure distribution depots.',
  'Clients need to email us to advise of any change outs so that we can ensure we are always displaying your current brochures.',
  `${NZBD} will always notify clients when their brochure stocks are running low.`,
  "It is our client's responsibility to supply new brochures to our distribution depots on time and in full to ensure continuous distribution.",
];

export const TermsCondition = () => (
  <>
    <InnerPageLayout
      title="Terms of Conditions"
      metaDescrib="Terms &amp; Conditions for NZBrochures Brochure Racks and distribution of brochures"
      canonicalUri="terms-and-conditions"
    >
      <UnorderedList spacing={3}>
        {CONDITION.map((item: string) => (
          <ListItem key={uuid()}>{item}</ListItem>
        ))}
      </UnorderedList>
      <H3>Brochures Delivery</H3>
      <P>
        Once an order is placed and accepted no refunds will be issued. On
        placing an order with {NZBD} you fully and unequivocally accept these
        terms and conditions. No returns or cancellations will be accepted. Once
        a order is placed please allow up to 5 days for delivery within New
        Zealand.
      </P>
      <H3>Supplier - Brochure Cancellation Policy</H3>
      <P>
        {`Any supplier can cancel brochure distribution with Alpine View (2012)
        Ltd - trading as New Zealand Brochure and Distribution by providing ${SITE_CONFIG.CANCELATION_PERIOD} advance notice in writing to`}
        <Link href="mailto:richard@nzbrochures.com" aria-label="email: richard">
          richard@nzbrochures.com
        </Link>{' '}
        There will be no exceptions to this policy and by distributing your
        brochures you fully accept and agree to this policy.
      </P>
    </InnerPageLayout>
  </>
);

export const TermsTrade = () => (
  <>
    <InnerPageLayout
      title="Terms of trade"
      canonicalUri="terms-of-trade"
      metaDescrib="Terms &amp; trade for NZBrochures Brochure Racks and distribution of brochures"
    >
      <P>
        {`All brochure displays contracts between ${NZBD} and the client are for a
        minimum of a 12-month period with a cancellation period of ${SITE_CONFIG.CANCELATION_PERIOD}.`}
      </P>
      <P>
        Payment is one month in advance by Automatic Payment on the 20th of each
        month or 6 month/12 months in advance by single invoice.
      </P>
      <P>
        {`${NZBD} undertakes to distribute the client's brochures to the outlets
        included in its services nominated subject to the client's brochures not
        being in competitive conflict with any given outlet. ${NZBD}
        undertakes to comply with individual outlet display requirements.`}
      </P>
      <P>
        Brochures will be displayed either in the display units supplied and
        installed by {NZBD} or in such other display devises that in the opinion
        of {NZBD} provides the brochures with ease of viewing and/or selection.
        Display outlets may change during contract periods and some outlets are
        restricted by space so not all brochures can be displayed. The client
        accepts and acknowledge that its brochure will not be displayed in every
        location.
      </P>
      <P>
        The client will ensure that adequate stocks of current brochures are
        delivered to {NZBD} prior to commencement date and as requested to
        enable efficient brochure distribution from time to time. {NZBD} will
        ask the client to supply new brochures up to 2 times in writing. After
        this time if brochures are not supplied then the client accepts and
        acknowledges that the brochures will not be on display in all the agreed
        locations and the client will continue to pay the display fee service
        without interruption.
      </P>
      <P>
        Brochure distribution is scheduled deliveries to all outlets. Brochure
        offtake may vary unexpectedly and therefore constant displays cannot be
        guaranteed in every location.
      </P>
      <P>
        {`This agreement may be cancelled by the client providing ${SITE_CONFIG.CANCELATION_PERIOD}'
        written notice, written acknowledgement must be obtained. ${NZBD} may also
        cancel this arrangement by providing the client with ${SITE_CONFIG.CANCELATION_PERIOD}' written
        notice.`}
      </P>
      <P>
        All brochure stocks supplied by the client are held by {NZBD} at the
        client's risk. For avoidance of any doubt {NZBD} will not be liable for
        any damage or any loss to the client for brochures being held at any
        time or any place by {NZBD}.
      </P>
      <P>
        The maximum liability of {NZBD} to the client for any act or omission on
        the part of {NZBD} shall not exceed the cost of providing the service
        under the contract for 1 month.
      </P>
      <P>
        The client warrants that the brochure is not in breach of any statutory
        or common law provision and that distribution will not result in any
        claim or action being made or taken against {NZBD}. The client will
        indemnify {NZBD} against any such claim or action.
      </P>
    </InnerPageLayout>
  </>
);
