import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { Headline } from '../../theme/ui-components/Typography';
import ContactForm from './ContactForm';

const ContactSection = () => {
  const colorMode = useColorModeValue('grey.50', 'unset');
  return (
    <>
      <Box
        bgColor={colorMode}
        bgImage={
          colorMode !== 'unset' ? 'url(/images/bgpattern-top.png)' : 'unset'
        }
        bgRepeat="no-repeat"
        bgPos="center top"
      >
        <Container mb={0}>
          <SimpleGrid columns={[1, 1, 2]} gap={[4, 6, 8, 10]}>
            <Box mb={5} pr={{ lg: 10 }}>
              <Headline size="lg">Contact us</Headline>
              <Stack>
                <Text lineHeight={1.8}>
                  For any inquireis, questions or commendations, please call us
                  based on your region or fill out the following form.
                </Text>
                <Text lineHeight={1.8}>
                  We are passionate about tourism! New Zealand Brochures &amp;
                  Distribution is a privately owned and operated NZ company that
                  has been trading in brochure distribution for over 30 years.
                  On 1st October, 2016 we merged our Carlton Tourism Promotions
                  and Matuka Brochure Distribution businesses together under a
                  single brand as we look to expand our business services
                  throughout New Zealand.
                </Text>
              </Stack>
            </Box>
            <ContactForm />
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
};

export default ContactSection;
