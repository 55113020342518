import React from "react";
import styled from "styled-components";

type alignVertical = "top" | "center" | "bottom";
type align = "fill" | "left" | "right" | "space-between";

interface StackProps {
  align?: align;
  alignVertical?: alignVertical;
  widths?: number[];
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const alignItems = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
};

const justifyContent = {
  fill: "space-between",
  "space-between": "space-between",
  left: "flex-start",
  right: "flex-end",
};

const StyledStack = styled.div<{
  alignVertical: alignVertical;
  align: align;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: ${(props) => alignItems[props.alignVertical]};
  justify-content: ${(props) => justifyContent[props.align]};
  line-height: inherit;
  
  max-width: 1110px;

  > * {
    flex: ${(props) => (props.align === "fill" ? 1 : "none")};
    margin-right: ${(props) => (props.align === "fill" ? "1em" : 0)};
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const StackedItem = styled.div<{ width: string | number }>`
  flex-basis: ${(props) => props.width}%;
  line-height: inherit;
`;

/*
  wrap children in col
  flex-basis makes flex redundant, have one
  accept widths on parent = Stack
  */

const Stack: React.FC<StackProps> = ({
  align = "fill",
  alignVertical = "center",
  widths,
  children,
  ...rest
}) => {
  let newChildren;
  if (align === "fill" || align === "space-between") {
    newChildren = React.Children.map(children, (child, index) => {
      let width: string | number = 0;
      if (widths) {
        width = `${widths[index]}` || 0;
      }

      return <StackedItem width={width}>{child}</StackedItem>;
    });
  } else {
    newChildren = children;
  }

  return (
    <StyledStack align={align} alignVertical={alignVertical} {...rest}>
      {newChildren}
    </StyledStack>
  );
};

export default Stack;
