import { useEffect } from 'react';
// import * as serviceWorker from "./serviceWorker";
import { ChakraProvider } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import Footer from 'components/Footer';
import Header from 'components/Header';
import { Routes } from 'Routes';
import theme from 'theme';

// fontsources
import '@fontsource/heebo';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import '@fontsource/heebo/900.css';
import { GoTopButton } from './components/GoTopButton';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const App = () => (
  <ChakraProvider theme={theme}>
    <ScrollToTop />
    <Header />
    <Routes />
    <Footer />
    <GoTopButton />
  </ChakraProvider>
);

export default App;
