import React from "react";
import { AspectRatio, Box, Image, AspectRatioProps } from "@chakra-ui/react";

interface Props extends AspectRatioProps {
  filename: string;
}
export const BlogImage = ({ filename, ...props }: Props) => {
  return (
    <Box>
      <AspectRatio ratio={6 / 4} {...props}>
        <Image
          src={`/images/blog/${filename}.jpg`}
          alt={filename}
          w="full"
          objectFit="cover"
        />
      </AspectRatio>
    </Box>
  );
};
