import React from 'react';
import { Container, Flex, SimpleGrid, GridItem, Stack } from '@chakra-ui/react';
import { H1, Headline, Paragraph } from '../../theme/ui-components/Typography';
import MetaHelmet from 'components/MetaHelmet';

interface Props {
  title: string;
  imagefile?: string;
  introText?: string;
  metaDescrib?: string;
  sidebar?: boolean;
  canonicalUri?: string;
  children: React.ReactNode;
}

const InnerPageLayout = ({
  title,
  imagefile,
  introText,
  metaDescrib,
  sidebar,
  canonicalUri,
  children,
}: Props) => {
  return (
    <>
      <MetaHelmet
        title={title}
        description={metaDescrib}
        canonicalUri={canonicalUri}
      />

      {imagefile && (
        <Flex
          role="banner"
          bgImage={`url(/images/${imagefile})`}
          bgPosition="center"
          bgSize={'cover'}
          w="full"
          h="300px"
          maxH={{ base: '25vh', lg: '33vh' }}
          alignItems="center"
          justifyContent="center"
          position="relative"
          _before={{
            content: '""',
            bgColor: 'rgba(0,0,0,0.38)',
            boxSize: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'block',
            zIndex: 1,
          }}
        >
          <Stack
            color="white"
            textAlign="center"
            zIndex={2}
            maxW="container.md"
          >
            <H1>{title}</H1>
            {introText && <Paragraph>{introText}</Paragraph>}
          </Stack>
        </Flex>
      )}

      <Container maxW="960px">
        {!imagefile && (
          <Stack mb={16}>
            <Headline as="h1" fontSize="5xl">
              {title}
            </Headline>
            {introText && <Paragraph>{introText}</Paragraph>}
          </Stack>
        )}
        {sidebar ? (
          <SimpleGrid
            mb={5}
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 6, lg: '6vw' }}
            gridTemplateColumns={{ lg: '2.5fr minmax(100px,1fr);' }}
          >
            <GridItem>{children}</GridItem>
            <GridItem>
              <Flex>sidebar</Flex>
            </GridItem>
          </SimpleGrid>
        ) : (
          children
        )}
      </Container>
    </>
  );
};

export default InnerPageLayout;
