import {
  Button,
  HStack,
  Image, Input, ListItem,
  Stack,
  Text
} from '@chakra-ui/react';
import { formatlink } from 'helpers';
import { useRef, useState } from 'react';
import { H5 } from 'theme/ui-components/Typography';
import { QuantityControlButton } from './QuantityControlButton';

type OrderedItemProps = {
  item: any;
  onRemove: any;
  onEnter: any;
  onAdd: any;
};
export const OrderedItems = ({
  item,
  onRemove,
  onEnter,
  onAdd,
}: OrderedItemProps) => {
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const [displayButton, setDisplayButton] = useState(false);
  return (
    <ListItem>
      <HStack spacing={6} alignItems="flex-start">
        <Image
          boxSize={28}
          src={`/images/brochures/${item.imgfile}.webp`}
          alt={item.name}
        />
        <Stack>
          <H5 mr={4}>{item.name}</H5>
          <Text fontSize="sm">Quantity: </Text>
          <HStack spacing={1}>
            <QuantityControlButton
              icon="minus"
              onClick={() => onRemove(item)}
            />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onEnter(
                  item,
                  quantityInputRef.current!.value !== ''
                    ? parseInt(quantityInputRef.current!.value)
                    : 1,
                );
                /* reset to default display */
                quantityInputRef.current!.value = '';
                setDisplayButton(false);
              }}
            >
              <HStack spacing={1} pos="relative">
                <Input
                  id={formatlink(item.name)}
                  type="number"
                  min={1}
                  max={999}
                  h="14"
                  minW={12}
                  border={displayButton ? '1px' : '0px'}
                  variant="unstyled"
                  textAlign={'center'}
                  placeholder={item.qty.toString()}
                  ref={quantityInputRef}
                  onChange={() => {
                    setDisplayButton(true);
                  }}
                />

                {displayButton && (
                  <Button
                    pos="absolute"
                    bottom={-8}
                    type="submit"
                    size={'xs'}
                    variant="unstyled"
                  >
                    update
                  </Button>
                )}
              </HStack>
            </form>

            <QuantityControlButton onClick={() => onAdd(item)} icon="plus" />
          </HStack>
        </Stack>
      </HStack>
    </ListItem>
  );
};
