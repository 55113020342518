import React from 'react';
import { Flex, Stack, Container, Heading, Box } from '@chakra-ui/react';
import { H1, Paragraph } from '../../theme/ui-components/Typography';
// import parse from 'html-react-parser';

interface Props {
  title: string;
  introText?: string;
  imagefile: string;
}
const ImageHeadline = ({ title, introText, imagefile }: Props) => {
  return (
    <Flex
      role="banner"
      bgColor="black"
      bgImage={`url(/images/background/${imagefile})`}
      bgPosition="center"
      bgSize={'cover'}
      w="full"
      h={{ base: '25vh', lg: '33vh' }}
      maxH="320px"
      minH="240px"
      alignItems="center"
      justifyContent="center"
      position="relative"
      zIndex={3}
      data-testid="image-headline"
      _before={{
        content: '""',
        bgColor: 'rgba(0,0,0,0.38)',
        boxSize: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'block',
        zIndex: 1,
      }}
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Box maxW="1024px" zIndex={2}>
        <Stack color="white" textAlign="center" spacing={1}>
          {title && (
            <Heading
              as="h1"
              fontSize={{ base: '20px', lg: '28px' }}
              letterSpacing="0.33px"
              // textTransform="uppercase"
              lineHeight={1.2}
            >
              {/* {parse(title)} */}
              {title}
            </Heading>
          )}
          {introText && (
            <Paragraph fontWeight={'500'} fontSize={'20px'}>
              {introText}
            </Paragraph>
          )}
        </Stack>
      </Box>
    </Flex>
  );
};

export default ImageHeadline;
