import React from 'react';
import LazyLoad from 'react-lazyload';
import uuid from 'react-uuid';
import {
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  VStack,
  AspectRatio,
} from '@chakra-ui/react';
import NAVITEMS from '../../../components/Navigation/navitems.json';
import { NavLink } from 'react-router-dom';
import { formatlink } from '../../../helpers';
import { Headline, Description } from '../../../theme/ui-components/Typography';
import misc from '../../../misc';
import WebpImage from '../../../components/WebpImage';
import Fading from '../../../components/Fading';

const RegionTiles: React.FC = () => {
  return (
    <>
      <Stack id="regions" spacing={8}>
        <VStack mb={5}>
          <Headline as="h1" size="lg" alignCenter>
            New Zealand Online Brochures
          </Headline>
          <Description>
            Explore our Nelson brochure display units, part of New Zealand's
            premier tourism distribution network. Serving South Island's key
            hubs - Picton, Blenheim, Marlborough, Nelson, Murchison, West Coast,
            Arthurs Pass, Christchurch, and Canterbury. Order or restock
            brochures today and benefit from our 20 years of brochure
            distribution expertise. We offer more than just brochures, including
            posters, vouchers, event info, books, magazines, and other
            tourism-related materials. Find us in South Island's top hotels,
            motels, campgrounds, rental car and campervan sites, backpackers,
            and airports.
          </Description>
        </VStack>
        <SimpleGrid columns={[1, 2, 3]} spacing={5} gap={[4, 6, 8]}>
          <Fading duration={550} cascade triggerOnce>
            {NAVITEMS.REGIONS.map((data: any) => {
              const value = data.title;
              const formatUri = data.uri
                ? formatlink(data.uri)
                : formatlink(data.title);
              return (
                <GridItem
                  key={uuid()}
                  pos="relative"
                  _hover={{
                    '.region-title': {
                      bgColor: 'red.400',
                    },
                  }}
                >
                  <LazyLoad offset={150} height={150} once>
                    <NavLink
                      aria-label={`navigate to ${value} page`}
                      to={formatUri}
                    >
                      <AspectRatio
                        ratio={3 / 4}
                        zIndex={1}
                        transition={misc.transitionAnimate}
                        _hover={{
                          opacity: 0.7,
                        }}
                        _after={{
                          content: "''",
                          display: 'block',
                          w: 'full',
                          h: 'full',
                          pos: 'absolute',
                          bottom: 0,
                          left: 0,
                          // bg: "linear-gradient(0deg, rgba(0,0,0,0.38) 0%, rgba(0,0,0,0) 25%)",
                        }}
                      >
                        <WebpImage
                          imgfile={`region-cover-${formatUri}`}
                          objectFit="cover"
                          altText={`${value}`}
                          h="full"
                        />
                      </AspectRatio>
                      <Heading
                        as="h3"
                        size={'md'}
                        // borderBottomLeftRadius="xl"
                        // borderTopRightRadius="xl"
                        fontWeight="700"
                        lineHeight="none"
                        textTransform="capitalize"
                        className="region-title"
                        px={{ base: 4, md: 6 }}
                        py={{ base: 2, md: 6 }}
                        // transform="translateY(-125%)"
                        bgColor="rgba(0,0,0,0.7)"
                        pos="absolute"
                        color={'white'}
                        top={0}
                        left={0}
                        zIndex={2}
                        transition={misc.transitionAnimate}
                      >
                        {value}
                      </Heading>
                    </NavLink>
                  </LazyLoad>
                </GridItem>
              );
            })}
          </Fading>
        </SimpleGrid>
      </Stack>
    </>
  );
};

export default RegionTiles;
