import { Helmet } from "react-helmet";
import { H2, Paragraph } from "../theme/ui-components/Typography";
import InnerPageLayout from "./InnerPageLayout";

export const NewPage = () => (
  <>
    <Helmet>
      <title>New_Page | {process.env.REACT_APP_TITLE}</title>
      <meta
        name="description"
        content={`${process.env.REACT_APP_TITLE}, ${process.env.REACT_APP_DESCRIPTION}`}
      />
    </Helmet>
    <InnerPageLayout
      title="heading"
      imagefile="DSC09498.jpg"
      introText="introText"
    >
      <H2>title</H2>
      <Paragraph>
        Once an order is placed and accepted no refunds will be issued. On
        placing an order with New Zealand Brochures &amp; Distribution you fully
        and unequivocally accept these terms and conditions. No returns or
        cancellations will be accepted. Once a order is placed please allow up
        to 5 days for delivery within New Zealand.
      </Paragraph>
    </InnerPageLayout>
  </>
);
