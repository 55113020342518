import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

interface Props extends HeadingProps {
  alignCenter?: boolean;
  size?: "lg" | "md" | "sm";
}

export const Headline = ({ alignCenter, size, ...props }: Props) => (
  <Heading
    as='h2'
    lineHeight={"none"}
    fontSize={
      size === "lg"
        ? { base: "32px", lg: "48px" }
        : { base: "24px", lg: "28px" }
    }
    fontWeight='700'
    textAlign={alignCenter? 'center' : "unset"}
    letterSpacing={size === "lg" ? "-1px" : "unset"}
    _after={{
      content: "''",
      display: size === "lg" ? "block" : "none",
      mx: alignCenter ? "auto" : "unset",
      my: { base: 4, lg: 5 },
      w: 12,
      h: "3px",
      bgColor: "red.400",
    }}
    {...props}
  />
);

export const H1 = (props: HeadingProps) => (
  <Heading
    as='h1'
    fontSize={{ base: "48", lg: "64" }}
    lineHeight='1.12'
    letterSpacing='-2px'
    // marginBottom={10}
    {...props}
  />
);

export const H2 = (props: HeadingProps) => (
  <Heading
    as='h2'
    fontSize={{ base: "36", lg: "48" }}
    lineHeight='1.15'
    // marginBottom={8}
    {...props}
  />
);

export const H3 = (props: HeadingProps) => (
  <Heading
    as='h3'
    fontSize={{ base: "26", lg: "36" }}
    lineHeight='1.375'
    mb={4}
    mt={8}
    {...props}
  />
);

export const H4 = (props: HeadingProps) => (
  <Heading
    as='h4'
    fontSize={{ base: "20", lg: "26" }}
    lineHeight='1.3'
    // marginBottom={5}
    {...props}
  />
);
export const H5 = (props: HeadingProps) => (
  <Heading
    as='h5'
    fontSize={{ base: "16", lg: "18" }}
    lineHeight='1.25'
    fontWeight='600'
    {...props}
  />
);
export const H6 = (props: HeadingProps) => (
  <Heading
    as='h6'
    fontSize={{ base: "14", lg: "16" }}
    // marginBottom={3}
    {...props}
  />
);
