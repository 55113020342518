import { Stack } from "@chakra-ui/react";
import { BlogImage } from "../../components/BlogImage";
import { Paragraph } from "../../theme/ui-components/Typography";
import InnerPageLayout from "../InnerPageLayout";

export const DisplayUnit = () => {
  return (
    <>
      <InnerPageLayout
        title="List Your Brochures - Expand Your Reach in High-Traffic Locations. Let Us Handle Distribution for You!"
        metaDescrib="List Your Brochures for 24/7 Visibility and Increased Reach with NZ Brochures & Distribution."
        canonicalUri="list-your-brochure"
      >
        <Stack spacing={6}>
          <BlogImage filename="freestanding-unit" />
          <Paragraph>
            Consider Marketing requires a mixed media approach. Online is
            critical as are brochures - what do you tend to do when you travel
            overseas?! Brochures are no use unless they are consistently in the
            hands of people who could be your customers. With NZ Brochures &amp;
            Distribution your brochures will be visible and available 24 hours a
            day in a large number of carefully chosen high traffic flow
            locations throughout the regions. The brochure displays units that
            hold your brochures are regularly serviced, cleaned and re-filled -
            7 days a week. With your brochures you are reaching customers
            already in the “buying: decision making” process and looking for
            exciting things to see and do. It's wet today - what should we do
            with the family? Don''t waste your valuable time trying to do this
            yourself. Our services are much more cost effective and your
            brochures will be guaranteed to remain in our brochure display
            units. If you try to do it yourself your brochures are likely to be
            re-cycled soon after you have left! Online portal -
            www.nzbrochures.com is a wonderful resource where customers can find
            a one stop ordering and delivery service for all the best tourism
            brochures and also click through directly to your own website for
            immediate information. We are well established and totally reliable
            - ask our existing clients or places where we display our client's
            brochures.
          </Paragraph>
        </Stack>
      </InnerPageLayout>
    </>
  );
};

