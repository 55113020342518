const container = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1320px'
}

const sizes = {
  container
}

export default sizes
