import { Box, Container, Flex, Stack, VStack } from '@chakra-ui/react';
import { Staff } from 'components/Staff';
import { Description, Headline } from 'theme/ui-components/Typography';
import MetaHelmet from '../../components/MetaHelmet';
import { ContactSection } from '../Contact';
import DisplayShelves from './DisplayShelves';
import RegionTiles from './RegionTiles';

const Homepage = () => {
  return (
    <>
      <MetaHelmet />
      <Flex
        bgColor="#3d6b9c"
        bgImage={{
          base: "url('/images/background/bg-banner-marlborough-sm.jpg')",
          md: "url('/images/background/bg-banner-marlborough.jpg')",
        }}
        bgRepeat="no-repeat"
        bgAttachment={{ lg: 'fixed' }}
        bgSize={{ base: 'cover' }}
        bgPos={{ base: 'bottom', md: 'center' }}
        pos="relative"
        minH="600px"
        alignItems="center"
        justifyContent="center"
        _before={{
          content: '""',
          bgColor: 'rgba(0,0,0,0.38)',
          boxSize: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'block',
          zIndex: 1,
        }}
      >
        <Container px={{ base: 4, lg: 0 }} maxW="container.lg" mb={0}>
          <DisplayShelves />
        </Container>
      </Flex>
      <Container as="section">
        <RegionTiles />
      </Container>
      <Box
        position="relative"
        bgColor="black"
        bgImage="url('/images/bg-mtcook.webp'), url('/images/background/bg-mtcook.jpg')"
        bgAttachment={{ lg: 'fixed' }}
        bgPos="center"
        bgSize="cover"
        color="white"
        h="33vh"
      />
      <Container maxW="container.lg" as="section" data-testid="team-section">
        <Stack>
          <VStack mb={10} spacing={0}>
            <Headline size="lg" alignCenter>
              Meet the team
            </Headline>
            <Description>
              We are passionate about tourism! New Zealand Brochures &amp;
              Distribution is a privately owned and operated NZ company that has
              been trading in brochure distribution for over 30 years. On 1st
              October, 2016 we merged our Carlton Tourism Promotions and Matuka
              Brochure Distribution businesses together under a single brand as
              we look to expand our business services throughout New Zealand.
            </Description>
          </VStack>
          <Staff />
        </Stack>
      </Container>
      <ContactSection />
    </>
  );
};

export default Homepage;
