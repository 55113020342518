import { Box, Container, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import uuid from 'react-uuid';
import * as Page from './pages';

import BrochureItem from './components/Brochures/BrochureItem';
import BROCHURELIST from './components/Brochures/brochuresList';
import { CartPage } from './components/Cart/CartPage';
import ImageHeadline from './components/ImageHeadline';
import MetaHelmet from './components/MetaHelmet';
import { navItems } from './components/Navigation/navitems';
import { formatlink, toCapitalize } from './helpers';

import { CheckoutButton } from './components/Cart/CheckoutButton';
import { ToggleNumber } from './components/ToggleNumber';
import { useCookies } from 'react-cookie';
import { cartItemType } from './components/Cart/cartItemType';
import SITE_CONFIG from './siteConfig';
import PrintButton from 'theme/ui-components/PrintButton';
import { H1 } from './theme/ui-components/Typography';

export const Routes = () => {
  /* -------------------------
        add to cart functions 
    */
  const [cartItems, setCartItems] = useState<cartItemType[]>([]);

  const [cookies, setCookie] = useCookies(['displayNumber', 'orders']);

  const [isDisplayNumber, setDisplayNumber] = useState(false);

  const toggleDisplayNumber = () => {
    setDisplayNumber(!isDisplayNumber);
    setCookie('displayNumber', !isDisplayNumber);
  };

  // toggle dispaly number base on cookies
  useEffect(() => {
    if (cookies.displayNumber) {
      setDisplayNumber(true);
    } else {
      setDisplayNumber(false);
    }
    // console.log('displayNumber', cookies.displayNumber);
  }, [cookies.displayNumber]);

  const handleKeyPress = (e: any) => {
    const keyPress = e.key;
    if (keyPress === 'd' || keyPress === 'b') {
      toggleDisplayNumber();
    }
  };

  function handleOnAdd(brochure: any) {
    // console.log('click add to cart => handleOnAdd');

    const exist = cartItems.find((cartitem) => cartitem.name === brochure.name);

    // ISSUE: below cause rerender .....
    if (exist) {
      // console.log('cartitem exist');
      setCartItems(
        cartItems.map((cartitem: any) =>
          cartitem.name === brochure.name
            ? { ...exist, qty: exist.qty + 1 }
            : cartitem,
        ),
      );
    } else {
      // console.log('cartitem not exist');
      setCartItems([...cartItems, { ...brochure, qty: 1 }]);
    }
  }

  // set cookies when cartItems change
  // useEffect(() => {
  //   // extract name and qty from cartItems
  //   const orders = cartItems.map((item) => {
  //     return { name: item.name, qty: item.qty };
  //   });

  //   // format orders to string and set cookies
  //   setCookie('orders', orders);

  // }, [cartItems]);

  function handleOnRemove(brochure: any) {
    const exist = cartItems.find((cartitem) => cartitem.name === brochure.name);

    if (exist?.qty === 1) {
      setCartItems(
        cartItems.filter((cartitem) => cartitem.name !== brochure.name),
      );
    } else {
      setCartItems(
        cartItems.map((cartitem: any) =>
          cartitem.name === brochure.name
            ? { ...exist, qty: exist!.qty - 1 }
            : cartitem,
        ),
      );
    }
  }
  function handleOnEnter(brochure: any, currentCount: number) {
    const exist = cartItems.find((cartitem) => cartitem.name === brochure.name);
    // console.log(currentCount);
    if (exist) {
      setCartItems(
        cartItems?.map((cartitem: any) =>
          cartitem.name === brochure.name
            ? { ...exist, qty: currentCount }
            : cartitem,
        ),
      );
    } else {
      console.log('not exist');
      setCartItems([...cartItems, { name: brochure.name, qty: currentCount }]);
    }

    // console.log(cartItems);
  }

  /* get each item's qty */
  const quantities = cartItems.map((item: any) => item.qty); // get all qtys [...]

  /* 👉️ accumulate the quantities */

  const totalBrochures = quantities.reduce((accumulate, current): number => {
    return accumulate + current;
  }, 0);

  // useEffect(() => {
  //     console.log(cartItems);
  // }, [cartItems]);

  /* -------------------------
        display number functions
    */

  interface BrochurePageProps {
    children?: React.ReactNode;
    location?: any;
  }
  const BrochurePage = ({ children, location }: BrochurePageProps) => {
    const isWestCoast = location === 'west coast';
    return (
      <>
        <MetaHelmet
          title={toCapitalize(location)}
          description={`Discover the Premier Brochure Display Units in ${toCapitalize(
            location,
          )} - Explore New Zealand's South Island Tourism Hubs. Order or restock brochures now`}
          canonicalUri={`${formatlink(location)}`}
        />
        <ImageHeadline
          title={`${toCapitalize(location)} Brochure Display Units`}
          imagefile={`bg-banner-${formatlink(location)}.jpg`}
          introText="New Zealand Tourism Distribution Hub"
        />
        <H1
          mb="0.125em"
          fontSize="20pt"
          letterSpacing={0}
          mt="1cm"
          textAlign="center"
          sx={{
            '@media screen': {
              display: 'none',
            },
          }}
        >
          {toCapitalize(location) + ' Brochure Display Units'}
        </H1>
        <Container py={[6, 10]} onKeyPress={(e) => handleKeyPress(e)} maxW='8xl'>
          <SimpleGrid
            mb={[6, 10]}
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 6, lg: 10 }}
            gridTemplateColumns={{ lg: '2.5fr minmax(100px,1fr);' }}
          >
            <GridItem
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Text>
                {/* These brochures are on brochure display units in the
            <Box as="span" textTransform="capitalize">
              &nbsp;
              {location}
            </Box> */}
                Discover the Premier Brochure Display Units in{' '}
                {toCapitalize(location)} - Explore New Zealand's South Island
                Tourism Hubs. Order or restock brochures now. Click on brochure
                to enlarge to a front and back cover view.
              </Text>
            </GridItem>
            <GridItem>
              <ToggleNumber
                onChange={() => toggleDisplayNumber()}
                isDisplayNumber={isDisplayNumber}
              />
            </GridItem>
          </SimpleGrid>
          <SimpleGrid
            columns={isWestCoast ? [2, 3, 5, 7, 8, 9] : [2, 3, 5, 7, 8]}
            spacingX={[2, 3]}
            spacingY={[10, 12, 16]}
            sx={{
              '@media print': {
                gridTemplateColumns: 'repeat(6, 1fr)',
              },
            }}
          >
            {children}
          </SimpleGrid>
          {/* <Box py={{ base: 12, lg: 20 }} textAlign="center">
            <PrintButton />
          </Box> */}
          {/* {displayBrochureRegion(location)} */}
        </Container>
      </>
    );
  };

  return (
    <Box as="main">
      {totalBrochures > 0 && <CheckoutButton quantities={totalBrochures} />}
      <RouterRoutes>
        {/* render the region pages */}
        {navItems.REGIONS.map((navItem: any) => {
          const formatValue = navItem.uri
            ? formatlink(navItem.uri)
            : formatlink(navItem.title);
          return (
            <Route
              key={uuid()}
              path={formatValue}
              element={
                <BrochurePage location={navItem.title}>
                  {BROCHURELIST.map((item: any) => {
                    return (
                      <Fragment key={uuid()}>
                        {item[navItem.regionName] && (
                          <BrochureItem
                            onAdd={handleOnAdd}
                            dataObject={item}
                            isDisplayNumber={isDisplayNumber}
                            broNumber={item[navItem.regionName]}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </BrochurePage>
              }
            />
          );
        })}
        {/* <Route path="all" element={<BrochurePage location="all" />} /> */}
        <Route path="list-your-brochure" element={<Page.DisplayUnit />} />
        <Route
          path="request-brochure-display-unit"
          element={<Page.RequestUnit />}
        />
        <Route path="privacy-policy" element={<Page.PrivacyPolicy />} />
        <Route path="contact" element={<Page.Contact />} />
        <Route path="about-us" element={<Page.AboutUs />} />
        <Route path="stand-locations/*" element={<Page.StandLocation />} />
        <Route path="terms-and-conditions" element={<Page.TermsCondition />} />
        <Route path="terms-of-trade" element={<Page.TermsTrade />} />
        <Route path="advertise-with-us" element={<Page.AdvertisePage />} />
        <Route path="warehouse-distribution-depots" element={<Page.Depots />} />
        {/* <Route path="changelog" element={<Page.Changelog />} /> */}
        <Route
          path="cart"
          element={
            <CartPage
              cartItems={cartItems}
              onAdd={handleOnAdd}
              onRemove={handleOnRemove}
              onEnter={handleOnEnter}
            />
          }
        />
        <Route index element={<Page.Homepage />} />
        <Route path="*" element={<Page.NoMatch />} />
      </RouterRoutes>
    </Box>
  );
};
