const baseStyle = {
  maxW: "container.xl",
  py: [12, 14, 20, 24, 32],
  px: { base: 6, lg: 8, xl: 4 },
  // mb: 4,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseStyle,
};
