// eslint-disable-next-line import/no-anonymous-default-export
export default {
    global: {
        html: {
            scrollBehavior: 'smooth',
        },
        body: {
            backgroundColor: '#fafafa',
            '&.chakra-ui-dark': {
                bgColor: '#202223',
            },
        },
        // styles for the `a`
        a: {
            // color: "red.400",
            _hover: {
                color: 'red.500',
                textDecoration: 'underline',
            },
        },
    },
};
