import React from 'react';
import uuid from 'react-uuid';
import NAVITEM from './navitems.json';
import { NavLink } from 'react-router-dom';
import { Box, Container, List, ListItem, BoxProps } from '@chakra-ui/react';
import { formatlink } from '../../helpers';
import misc from '../../misc';

export interface NaviItemTypes {
  title: string;
  uri?: string;
  path?: string | null;
  active?: boolean;
  submenu?: object[];
}

const NaviListItem = ({ LOOP }: any) => (
  // TODO: find the loop type
  <>
    {LOOP.map((data: NaviItemTypes) => {
      const value = data.title;
      const formatUri = data.uri
        ? formatlink(data.uri)
        : formatlink(data.title);
      return (
        <ListItem
          key={uuid()}
          textTransform="capitalize"
          color="grey.100"
          px={{ base: 2, xl: 4 }}
          py={5}
          fontWeight="700"
          fontSize={{ base: '14px', '2xl': 'md' }}
          transition={misc.transitionAnimate}
          sx={{
            a: {
              _hover: {
                color: 'white',
              },
              '&.active': {
                color: 'red.400',
              },
            },
          }}
        >
          <NavLink
            aria-label={`navigate to ${value} page`}
            to={value === 'home' ? '/' : formatUri}
          >
            {value}
          </NavLink>
          {/* 如果有submenu再深入一層迴圈 */}
          {/* {data.submenu !== undefined && (
            <List className="submenu">
              {data.submenu.map((sub: any) => (
                <ListItem  key={uuid()}>
                  <NavLink to={sub.title}>{sub.title}</NavLink>
                </ListItem>
              ))}
            </List>
          )} */}
        </ListItem>
      );
    })}
  </>
);

const Navigation = ({ ...rest }: BoxProps) => (
  <Box
    as="nav"
    bgColor={{base: 'black', '2xl': 'transparent'}}
    className="deskop-menu"
    // display={{ base: 'none', lg: 'block' }}
    pb="3px"
    borderTop={{ base: '1px solid', '2xl': 'none' }}
    borderTopColor="red.400"
    zIndex={999}
    {...rest}
  >
    <Container px={0} py={0}>
      <List display="flex" justifyContent={'center'}>
        {/* <NaviListItem LOOP={NAVITEM.HOME} /> */}
        <NaviListItem LOOP={NAVITEM.REGIONS} />
        <NaviListItem LOOP={NAVITEM.OTHERS} />
      </List>
    </Container>
  </Box>
);
export default Navigation;

/*
 
TO DO:

ReactDom.createPortal
點擊背景可以 setActive(false)
 
 */
