import React from 'react';
import { H3, Paragraph as P } from '../theme/ui-components/Typography';
import InnerPageLayout from './InnerPageLayout';

export const PrivacyPolicy = () => (
  <>
    <InnerPageLayout
      title="Privacy Policy"
      metaDescrib="Privacy policy for NZBrochures"
      canonicalUri="privacy-policy"
    >
      <H3>Collection and use of personal information on this website</H3>
      <P>
        NZBrochure's customer privacy policy sets out how we collect, use,
        protect and share your personal information. The privacy of your
        personal information is important to us, so we will not provide your
        information to any third parties.
      </P>

      <H3>Cookies</H3>
      <P>This website does not use cookies to store personal data.</P>
      <P>If you have any questions, please email us</P>
    </InnerPageLayout>
  </>
);
