import React from "react";
import { Fade, FadeProps } from "react-awesome-reveal";

export interface MockProp extends FadeProps {
  children: React.ReactNode; // 👈️ add type to fix the Fade {children} issue
}

const Fading = ({ children, ...props }: MockProp) => {
  return <Fade {...props}>{children}</Fade>;
};

export default Fading;
