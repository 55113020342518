import React from "react";
import uuid from "react-uuid";
import { UnorderedList, ListItem } from "@chakra-ui/react";
import { Paragraph as P, H3 } from "../theme/ui-components/Typography";
import InnerPageLayout from "./InnerPageLayout";

export const AdvertisePage = () => (
  <>
    <InnerPageLayout
      title="Advertise with Us"
      metaDescrib="Reach a Vast Audience of Travelers in Marlborough, Nelson, Canterbury and West Coast Regions. High-Visibility, Effective Marketing Opportunities Await!"
      canonicalUri='advertise-with-us'
    >
      <H3>Product purpose</H3>
      <P>
        To provide essential information to visitors and potential visitors in a
        form in which it will be easily accessible to them, and in doing so to
        provide advertisers with effective access to the defined target
        audience.
      </P>
      <H3>Target audience</H3>
      <P>
        Overseas and domestic visitors to the Marlborough / Nelson / West Coast
        regions whether traveling by air, coach, car, campervan, or backpacking.
      </P>
      <H3>Presentation</H3>
      <P>
        Travel wallet/pocket size. Portable, durable, four colour, glossy,
        easy-to-use format.
      </P>
      <H3>Contents</H3>
      <P>
        Complete visitor guide, with high-quality maps, and comprehensive
        visitor information in the easily accessible form detailing the specific
        areas of Marlborough / Nelson / West Coast while presenting the region
        as a linked touring route.
      </P>
      <H3>Circulation</H3>
      <P>95,000 copies per year, printed as 2 issues.</P>
      <H3>Distribution</H3>
      <P>
        Available free in both the International and Domestic Terminals at
        Auckland International Airport - (the gateway to the 2,500 000 plus
        tourists visiting New Zealand this year), plus 140 major visitor
        locations in Auckland. Over 125 serviced brochure display units in
        Christchurch, another 140 + in Wellington. Queenstown has 105 serviced
        outlets. Marlborough/Nelson/West Coast has a concentrated display with 3
        front-line pockets in all Carlton's displays. Detailed lists are
        attached. Available to all Information Offices nationwide.
      </P>
      <P>
        Closely monitored on a regular basis throughout Carlton's distribution
        network. Displayed and freely available at the Wellington ferry
        terminal.
      </P>
      <H3>Advertising rates</H3>
      <P>Refer to the separate rate schedule for details</P>
      <H3>Market size</H3>
      <P>
        As stated this year, will bring over 2,700 000 overseas visitors to New
        Zealand. A growing trend is for visitors to make their travel plans on
        arrival, rather than arriving with set schedules. Domestic visitor
        numbers are significantly greater
      </P>
      <H3>Summary</H3>
      <P>
        The success and uniqueness of the Great Time Guide have been established
        as a result of all of the following factors being combined into a
        soundly co-ordinate and managed
      </P>
      <UnorderedList spacing={3}>
        {summary.map((item: string) => (
          <ListItem key={uuid()}>{item}</ListItem>
        ))}
      </UnorderedList>
      <H3>Locally serviced</H3>
      <P>
        In order to provide the best possible service to our clients, the
        Marlborough / Nelson / West Coast Great Time Guide is owned and operated
        locally. This ensures the product is truly relevant to local needs and
        effective in promoting the featured advertisers and the region as a
        whole while retaining the excellent quality and the reliable
        distribution of a national display network.
      </P>
    </InnerPageLayout>
  </>
);

const summary = [
  "Content and quality to ensure genuine visitor usefulness.",
  "Print run geared to market needs.",
  "Targeted at overseas visitors as soon after their arrival in New Zealand as possible and available to all information centres throughout New Zealand serving the needs of both domestic and overseas visitors.",
  "Distribution management of the highest standard, to ensure effective service to the visitor. The nature of the visitor market is such that it is critical that media servicing the market must be strong in ALL of these key areas.",
  "It is for this reason that the Great Time Guides have developed their position of market leadership.",
];
