import { Box, Flex, Stack } from '@chakra-ui/react';
import { formatlink } from 'helpers';
import { Link, LinkProps, Outlet, useSearchParams } from 'react-router-dom';
import uuid from 'react-uuid';
import Pill from 'theme/ui-components/Pill';
import { regions, STANDINGS } from './locationList';

const NumberIndicator = ({ region = '' }) => {
  /* get specific region number */
  const regionStandings = STANDINGS.map((data) => {
    // console.log(data);
    return data.region === region ? data.list.length : 0; // output as an array
  }).reduce((accumulate, current) => accumulate + current, 0); // accumulate + current value

  /* get all regions number */
  const totalStandings = STANDINGS.map((data) => {
    return data.list.length;
  }).reduce((accumulate, current) => accumulate + current, 0); // 0 is the starting value

  return (
    <>
      {region
        ? regionStandings > 1 && `(${regionStandings})`
        : `(${totalStandings})`}
    </>
  ); // display only if the number > 1
};

export const LocationLayout = () => {
  let [searchParams] = useSearchParams();
  let getRegion = searchParams.get('region');

  interface RegionFilterProps extends Omit<LinkProps, 'to'> {
    region: string;
    number?: number;
  }

  function RegionFilter({
    region,
    children,
    number,
    ...props
  }: RegionFilterProps) {
    const formatValue = formatlink(region);
    let isActive = getRegion === formatValue;
    return (
      <Link
        to={isActive ? '/stand-locations' : `?region=${formatValue}`}
        {...props}
      >
        <Pill variant={isActive ? 'solid' : 'outline'}>{children}</Pill>
      </Link>
    );
  }
  return (
    <Stack spacing={{ base: 4, lg: 8 }}>
      <Box
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Flex
          flexWrap="wrap"
          rowGap={{ lg: 2 }}
          alignItems="center"
          sx={{
            'a, p': {
              d: 'inline-block',
              mb: { base: 2, xl: 0 },
              mr: 2,
            },
            'a:hover': {
              textDecoration: 'none',
            },
          }}
        >
          {/* <Text display="inline" fontWeight="bold">
                Filter:
              </Text> */}
          <Link to="/stand-locations" style={{ display: 'inline-block' }}>
            <Pill variant={getRegion === null ? 'solid' : 'outline'}>
              All <NumberIndicator />
            </Pill>
          </Link>
          {regions.map((dataRegion, index) => (
            <RegionFilter key={index} region={dataRegion}>
              {dataRegion} <NumberIndicator region={dataRegion} />
            </RegionFilter>
          ))}
        </Flex>
      </Box>
      <Outlet />
    </Stack>
  );
};
