import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

type ToggleNumberType = {
  onChange: () => void;
  isDisplayNumber: boolean;
};
export const ToggleNumber = ({
  onChange,
  isDisplayNumber,
}: ToggleNumberType) => (
  <FormControl
    display="flex"
    alignItems="center"
    justifyContent={'flex-end'}
    mb={4}
    sx={{
      '@media print': {
        display: 'none',
      },
    }}
  >
    <FormLabel htmlFor="brochure-number" mb="0" lineHeight={'none'}>
      Display Brochure Number
    </FormLabel>
    <Switch
      id="brochure-number"
      onChange={onChange}
      isChecked={isDisplayNumber}
    />
  </FormControl>
);
