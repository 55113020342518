import { Box } from '@chakra-ui/react';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import misc from '../../misc';

interface Props extends Omit<LinkProps, 'to'> {
    path: string;
    hashtag?: string;
    children: React.ReactNode;
}

const InternalLink = ({ path, hashtag, children, ...props }: Props) => {
    return (
        <Link
            to={{
                pathname: path,
                hash: hashtag,
            }}
            {...props}
        >
            <Box as="span" transition={misc.transitionAnimate} textDecor="underline">
                {children}
            </Box>
        </Link>
    );
};

export { InternalLink };
