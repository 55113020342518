import { getColor, mode } from "@chakra-ui/theme-tools";

const parts = ["field", "addon"];
type Dict = Record<string, any>;

function baseStyle(props: Dict) {
  const { colorScheme: c } = props;
  return {
    bg: mode("white", `${c}.100`)(props),
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transition: "all 0.33s",
  };
}

const size = {
  md: {
    fontSize: "md",
    borderRadius: "md",
    px: 4,
    h: "60px",
  },
};

const sizes = {
  md: {
    field: size.md,
    addon: size.md,
  },
};

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  const { colorScheme: c } = props;
  return {
    focusBorderColor: fc || mode(`deepblue.400`, `${c}.300`)(props),
    errorBorderColor: ec || mode("red.400", "red.500")(props),
  };
}

function variantFilled(props: Record<string, any>) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
  return {
    field: {
      width: "100%",
      border: "3px solid",
      borderColor: "black.400",
      borderRadius: "md",
      color: "black.400",
      bg: mode("white", `whiteAlpha.50`)(props),
      _hover: {
        bg: mode("grey.50", `whiteAlpha.100`)(props),
        // color: mode("black", `whiteAlpha.50`)(props),
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        bg: "grey.100",
        cursor: "not-allowed",
      },
      _invalid: {
        bg: "red.100",
        borderColor: getColor(theme, ec),
        color: getColor(theme, ec),
      },
      _focus: {
        bg: mode(`grey.50`, `whiteAlpha.100`)(props),
        borderColor: getColor(theme, fc),
        _placeholder: {
          opacity: "0",
        },
      },
    },
    addon: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode("gray.100", "whiteAlpha.50")(props),
    },
  };
}

function variantSorted(props: Record<string, any>) {
  return {
    field: {
      border: "3px solid",
      borderColor: "transparent",
      color: "deepblue.500",
      bg: mode("transparent", "whiteAlpha.50")(props),
      _hover: {
        bg: mode("deepblue.100", "whiteAlpha.100")(props),
        color: "deepblue.500",
      },
      _focus: {
        background: "deepblue.100",
        borderColor: "transparent",
        _placeholder: {
          opacity: "0",
        },
      },
    },
  };
}

const variantUnstyled = {
  field: {
    bg: "transparent",
    px: 0,
    height: "auto",
    _hover: {
      bg: "transparent",
      color: "inherit",
    },
    _focus: {
      bg: "transparent",
      color: "inherit",
    },
  },
  addon: {
    bg: "transparent",
    px: 0,
    height: "auto",
  },
};
const variants = {
  filled: variantFilled,
  sorted: variantSorted,
  unstyled: variantUnstyled,
};

const defaultProps = {
  size: "md",
  variant: "filled",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
