import { type standing } from './locationList';

export const chchHolidayHomes: standing = {
  region: 'Christchurch & Canterbury',
  category: 'Christchurch Holiday Homes',
  list: [
    "Lux B&B Care",
    "New Brighton x2",
    "St Albans x1",
    'Jeffreys Road',
    'Leithfield Beach',
    'Lichfield Apartment',
    'Chatham',
    '49 Longmeir *',
    'Albemarle Villa *',
    'Armagh Apartment *',
    'Aspin Studio *',
    'Aspin Suite *',
    'Aspin Villa *',
    'Barbadoes Apartment *',
    'Beach Road Beauty *',
    'Brook Haven *',
    'Brookside Villa *',
    'Carlton Penthouse *',
    'Carlton Villas *',
    'Carlton Villa 2 *',
    'Carlton Villa 3 *',
    'Carruthers Villa *',
    'Chalesworth Villa *',
    'Clarence House *',
    'Clarence Villas *',
    'Comfy Villa *',
    'Eversleigh St Villa *',
    'Ilam Villa *',
    'Kaiapoi Lakes *',
    'Kea Lodge *',
    'Leinster Villa *',
    'Lynfield Villa *',
    'Madras St Apartment 1 *',
    'Madras St Apartment 2 *',
    'Madras St Apartment 3 *',
    "Mary's Villa *",
    'Parklane Apartment 4 *',
    'Parlane Apartments *',
    'Parlane Apartment 1 *',
    'Parlane Apartment 2 *',
    'Parlane Apartment 3 *',
    'Panorama Rd*',
    'Parkstone Avenue *',
    'Pines Beach *',
    'Racecourse Villa *',
    'Regents Villas *',
    'Regents Villa A *',
    'Regents Villa B *',
    'Riccarton Loft *',
    "Rosemary's Villa *",
    'Russley Retreat *',
    'Salisbury Apartment 1 *',
    'St Albans Villa *',
    'Stream Lea *',
    'Sunvale *',
    'The Apartment Within *',
    'Westfield Close *',
    'Weston Villa *',
    'Woodbury Villa *',
    'Orari B & B Gloucester St,',
  ],
};
