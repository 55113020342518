import {
  Box,
  Button,
  GridItem,
  Heading,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { InternalLink } from 'pages/DisplayUnit/RouteLink';
import { useState, useRef, useEffect } from 'react';
import LazyLoad, { forceVisible } from 'react-lazyload';
import {
  BrochureItemProps,
  brochureSize,
  brochureTall,
} from './BrochureItemProps';
import { BrochureNumber } from './BrochureNumber';
import { FlipCard } from './FlipCard';
import { PopupModal } from './PopupModal';

const BrochureItem = ({
  isDisplayNumber,
  broNumber,
  openLink,
  onAdd,
  dataObject,
  forceDisplay = false,
  hideButton = false,
  ...props
}: BrochureItemProps) => {
  // Forces the component to display regardless of whether the element is visible in the viewport
  // if (forceDisplay === true) {
  //     forceVisible();
  // }
  const { isOpen, onOpen, onClose } = useDisclosure();

  const placeHolder = dataObject.name === 'TBC';

  /* useRef method to set count number */

  // const [refCounter, setRefCounter] = useState(0);

  // const countRef = useRef(0);
  // const handle = () => {
  //     countRef.current++;
  //     setRefCounter(countRef.current);
  //     console.log(`Clicked ${countRef.current} times`);
  // };

  /* set up initail value function */
  function initailCount(value: number) {
    return value; // only run one time at a time
  }
  const [count, setCount] = useState(() => initailCount(0)); // pass a founction to call the countInitail

  //   useEffect(() => {
  //     console.log('count', count);
  //   }, [count]);

  return (
    <>
      <GridItem
        order={broNumber}
        sx={{
          '@media print': {
            height: '150pt',
            mb: '0.125cm',
          },
        }}
        {...props}
      >
        <Stack
          position="relative"
          h="full"
          spacing={0}
          justifyContent="space-between"
          sx={{
            'a.Link': {
              display: 'block',
            },
          }}
          // TDDO: fix the brochure flip animation
          _hover={{
            '.brochure-number': {
              opacity: 1,
              visibility: 'visible',
            },
            '.flip-inner': {
              /* Do an horizontal flip when you move the mouse over the flip box container */
              transform: 'rotateY(0.5turn)',
            },
            // '.flip-card--front': {
            //   transform: 'rotateY(0.5turn)',
            // },
            // '.flip-card--back': {
            //   transform: 'rotateY(0)',
            // },
 
          }}
        >
          {broNumber && (
            <BrochureNumber
              number={broNumber}
              opacity={isDisplayNumber ? 1 : 0}
              visibility={isDisplayNumber ? 'visible' : 'hidden'}
              className="brochure-number"
            />
          )}
          {placeHolder ? (
            <Box
              display="flex"
              justifyContent={'center'}
              alignItems={'center'}
              bgColor={'gray.200'}
              h={brochureTall}
              maxW={brochureSize}
              fontWeight={'bold'}
            >
              TBC
            </Box>
          ) : (
            <Box
              onClick={onOpen}
              aria-label={`view ${dataObject.name} modal`}
              cursor="pointer"
              role="button"

            >
              <Box
                boxSize="full"
                maxW={brochureSize}
                margin="auto"
                textAlign="center"
                position="relative"
                sx={{
                  perspective: '1000px', //* Remove this if you don't want the 3D effect */
                }}
              >
                {/* <LazyLoad
                  once
                  offset={160}
                  // height={240}
                  // style={{
                  //   height: '100%',
                  // }}
                > */}
                  <Box
                    as="figure"
                    aria-labelledby={dataObject.name}
                    role="group"
                    className="flip-inner"
                    transition={{ md: 'transform 0.8s' }}
                    h={brochureTall}
                    position="relative"
                    tabIndex={0}
                    sx={{
                      transformStyle: { md: 'preserve-3d' },
                    }}
                  >
                    {dataObject.imgfile && (
                      <FlipCard
                        className="flip-card--front"
                        facing={dataObject.facing || 'front'}
                        imgSrc={dataObject.imgfile}
                        alt={`front side brochure of ${dataObject.name}`}

                      />
                    )}
                    <FlipCard
                      className="flip-card--back"
                      facing="back"
                      imgSrc={dataObject.imgfile}
                      alt={`back side brochure of ${dataObject.name}`}
                      transform="rotateY(-180deg)"
                    />
                  </Box>
                {/* </LazyLoad> */}
                {onAdd && (
                  <Heading
                    as="figcaption"
                    id={dataObject.name}
                    fontSize="sm"
                    my={4}
                    // color="black.300"
                    textTransform="capitalize"
                    textAlign="center"
                    onClick={onOpen}
                    cursor="pointer"
                    _hover={{
                      color:'red.500'
                    }}
                  >
                    {dataObject.name}
                  </Heading>
                )}
              </Box>
            </Box>
          )}

          {!placeHolder && (
            <Stack alignItems="center">
              {!hideButton && (
                <>
                  {count > 0 && count}
                  <Button
                    variant="outline"
                    size="sm"
                    borderRadius="full"
                    py={3}
                    px={5}
                    onClick={() => {
                      onAdd(dataObject);
                      setCount(count + 1);
                    }}
                    sx={{
                      '@media print': {
                        display: 'none',
                      },
                    }}
                  >
                    {count !== 0 ? '+' : 'order'}
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Stack>
      </GridItem>

      {/* -------------------
          open the popup Modal Dialog
        ---------------------- */}

      {!placeHolder && (
        <PopupModal isOpen={isOpen} onClose={onClose} data={dataObject} />
      )}
    </>
  );
};

export default BrochureItem;
