import {
  AspectRatio,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';
import uuid from 'react-uuid';
import WebpImage from './WebpImage';
import { formatlink } from '../helpers';
import { EmailIcon, PhoneIcon } from '../theme/ui-components/icons';
import staffData from '../data/staffData';


export const Staff = () => (
  <SimpleGrid columns={[1, 2, 3]} spacing={6} gap={[4, 6, 8, 16]} mx="auto">
    {staffData.map((data: any) => {
      const value = formatlink(data.firstname);
      return (
        <GridItem key={uuid()}>
          <LazyLoad offset={150} height={150} once>
            <AspectRatio ratio={1} mb={6}>
              <WebpImage
                imgfile={`pic-${data.imgfile ? data.imgfile : value}`}
                borderRadius="full"
                objectFit="cover"
                altText={`${value}`}
                boxSize="256px"
                filter="grayscale(1)" />
            </AspectRatio>
          </LazyLoad>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Heading
                as="h4"
                fontSize="28px"
                textTransform="capitalize"
                letterSpacing={'-0.33px'}
              >
                {value} {data.lastname}
              </Heading>
              <Text
                color="grey.300"
                // fontSize="md"
                textTransform="capitalize"
              >
                {data.jobtitle}
              </Text>
            </Stack>
            <Stack spacing={1}>
              <Link href={`tel:${data.phone}`} aria-label={data.phone}>
                <PhoneIcon boxSize={5} mr={3} />
                <Text as="span" color="red.500">
                  {data.phone}
                </Text>
              </Link>
              <Link
                href={`mailto:${value}@nzbrochures.com`}
                aria-label={data.email}
              >
                <EmailIcon boxSize={5} mr={3} />
                <Text as="span" color="red.500">
                  {value}@nzbrochures.com
                </Text>
              </Link>
            </Stack>
            {/* {(data.area || data.address) && <Divider w="24px" />} */}
            {data.area && <Text color="grey.400">{data.area}</Text>}
            {data.address && <Text color="grey.400">{data.address}</Text>}
          </Stack>
        </GridItem>
      );
    })}
  </SimpleGrid>
);
