import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  List,
  ListItem,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import uuid from "react-uuid";
import { formatlink } from "helpers";
import { HamburgerMenuIcon } from "theme/ui-components/icons";
import { ColorModeSwitcher } from "components/ColorModeSwitcher";
import FOOTER from "components/Footer/footerlinks.json";
import NAVITEM from "components/Navigation/navitems.json";

const NaviListItem = ({ LOOP, setClose }: any) => (
  <>
    {LOOP.map((data: any) => {
      const value = data.title;
      return (
        <ListItem
          key={uuid()}
          textTransform="capitalize"
          fontWeight="600"
          fontSize={{ lg: "xl" }}
          sx={{
            ".active": {
              color: "red.400",
            },
          }}
        >
          <NavLink
            onClick={setClose}
            to={value === "home" ? "/" : formatlink(value)}
          >
            {value}
          </NavLink>
        </ListItem>
      );
    })}
  </>
);

export default function StyleDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <>
      <IconButton
        border="none"
        boxSize={10}
        display="flex"
        justifyContent="center"
        alignItems="center"
        aria-label="open navigation"
        bg="transparent"
        icon={
          <HamburgerMenuIcon w={{ base: 6, lg: 8 }} h="auto" color="white" />
        }
        onClick={onOpen}
        _hover={{
          cursor: "pointer",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        }}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={isMobile ? "xs" : "sm"}
        onClose={onClose}
      >
        <DrawerOverlay>
          <DrawerContent>
            <ColorModeSwitcher pos="absolute" top={4} left={4} />
            <DrawerCloseButton
              fontSize={"22px"}
              size={"lg"}
              top={4}
              right={4}
            />
            {/* <DrawerHeader /> */}
            <DrawerBody>
              <Box h="full" display="flex" alignItems="center">
                <Box as="nav" className="mobile-menu" pl={4}>
                  <List spacing={2}>
                    <NaviListItem setClose={onClose} LOOP={NAVITEM.HOME} />
                    {isMobile && (
                      <>
                        <NaviListItem
                          setClose={onClose}
                          LOOP={NAVITEM.REGIONS}
                        />
                        <NaviListItem
                          setClose={onClose}
                          LOOP={NAVITEM.OTHERS}
                        />
                      </>
                    )}
                    <NaviListItem
                      setClose={onClose}
                      LOOP={FOOTER.opportunities}
                    />
                    <NaviListItem setClose={onClose} LOOP={FOOTER.more} />
                  </List>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}
