import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { send } from 'emailjs-com';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { H4 } from 'theme/ui-components/Typography';
import { InputElementControl } from 'ui-form';
import SITE_CONFIG from '../../siteConfig';
import { OrderedItems } from './OrderedItems';
import uuid from 'react-uuid';
import { orderCaptureSchema } from 'helpers/formvalidation';
import MetaHelmet from 'components/MetaHelmet';

/* 

TODO: 

- [ ] Add a "price total" to the form submission

*/

const regionList = [
  'Picton',
  'Blenheim',
  'Nelson',
  'Marlborough',
  'Arthurs Pass',
  'North Canterbury',
  'Canterbury',
  'Christchurch',
  'West Coast',
  'Haast',
  'Fox and Franz Josef',
  'Whataroa',
  'Hokitika',
  'Greymouth',
  'Punakaiki',
  'Others',
];

export const CartPage = ({ cartItems, onAdd, onRemove, onEnter }: any) => {
  // const { cartItems, onAdd } = props; // another way to define the props
  const [captchaKey, setCaptchaKey] = useState<String>('');
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [errorSubmission, setErrorSubmission] = useState(false);
  const submitted = !errorSubmission && !successfulSubmission;
  const errorSubmissionMsg =
    SITE_CONFIG.ERROR_SUBMIT_MESSAGE + SITE_CONFIG.CONTACT_NUMBER;

  function CollectNameAndQty(item: any) {
    return [item.name, item.qty].join(' * ');
  }

  const orders = cartItems.map((item: any) => CollectNameAndQty(item)); // return [...]

  // collect image url from orders
  const orderImageUrl = cartItems.map((item: any) => item.imgfile);

  // collect image url from orders

  /* TODO:

    - add <br> for each item for the readable email
    - add image for each item for the readable email
    - success message
    
    */

  // const ordersString = orders.map((item: string) => (
  //   <List key={uuid()}>
  //     <ListItem>{item}</ListItem>
  //   </List>
  // ));

  const ordersString = orders.join(', '); // return a string

  // useEffect(() => {
  //   console.log(orders);
  //   console.log(orderImageUrl);
  // }, [orders, orderImageUrl]);

  /* calculate the cost */
  const quantityArray = cartItems.map((item: any) => item.qty); // get all qtys [...]

  /* 👉️ get the summaries of the quantityArray */

  const totalBrochures = quantityArray.reduce(
    (accumulate: number, current: number) => {
      return accumulate + current;
    },
    0,
  );

  /* set the fixed price */
  const singlePrice = 0.08;
  const shippingFee = 15;
  const gst = 0.15;
  const estimatedCost = (
    totalBrochures * singlePrice +
    totalBrochures * singlePrice * gst +
    shippingFee
  ).toFixed(2);

  return (
    <>
      <MetaHelmet title="cart" canonicalUri="cart" />
      <Container>
        <Flex flexDir={{ base: 'column', lg: 'row' }} gap={10} w="full">
          <Box w={{ lg: '50%' }}>
            <H4 mb={8}>Your orders</H4>
            <List spacing={5}>
              {cartItems.length === 0 ? (
                <Text>Cart is empty</Text>
              ) : (
                cartItems.map((item: any) => (
                  <OrderedItems
                    key={uuid()}
                    item={item}
                    onRemove={onRemove}
                    onEnter={onEnter}
                    onAdd={onAdd}
                  />
                ))
              )}
            </List>
          </Box>

          <Box w={{ lg: '50%' }}>
            {/* summary */}
            <List
              bgColor="rgba(0,0,0,0.05)"
              mb={8}
              p={10}
              spacing={2}
              mt={{ base: 10, lg: 0 }}
              borderRadius="lg"
            >
              <ListItem>Total: {totalBrochures} brochures</ListItem>
              <ListItem>
                cost: ${(totalBrochures * singlePrice).toFixed(2)}{' '}
              </ListItem>
              <ListItem>
                GST: ${(totalBrochures * singlePrice * gst).toFixed(2)}
              </ListItem>
              <ListItem>shipping fee: ${shippingFee} (estimated only)</ListItem>
              <ListItem>
                <Text fontWeight="bold">Estimated total:</Text>
                <Text fontWeight={500}>
                  ${estimatedCost}
                  &nbsp; NZD
                </Text>
              </ListItem>
            </List>
            {/* form */}
            {successfulSubmission ? (
              <Stack>
                <Heading as="h2">Message sent</Heading>
                <Text>{SITE_CONFIG.SUBMITTED_MESSAGE}</Text>
              </Stack>
            ) : (
              <Formik
                initialValues={{
                  from_name: '',
                  to_name: '',
                  // to_email: 'test@archieweb.nz',
                  to_email: SITE_CONFIG.EMAILJS_TO_EMAIL,
                  cc_email: '',
                  bcc_email: SITE_CONFIG.EMAILJS_BCC,
                  region: '',
                  mobilenumber: '',
                  address: '',
                  postcode: '',
                  note: '',
                  estimatedCost,
                }}
                validationSchema={orderCaptureSchema}
                onSubmit={(values) => {
                  // Carbon Copy to...
                  if (values.region) {
                    switch (values.region) {
                      case 'Picton':
                      case 'Blenheim':
                      case 'Nelson':
                      case 'Marlborough':
                        values.cc_email = 'rhonda@nzbrochures.com';
                        values.to_name = 'Rhonda';
                        break;
                      case 'Arthurs Pass':
                      case 'North Canterbury':
                      case 'Canterbury':
                      case 'Christchurch':
                        values.cc_email = 'jo@nzbrochures.com';
                        values.to_name = 'Jo';
                        break;
                      case 'West Coast':
                      case 'Haast':
                      case 'Fox and Franz Josef':
                      case 'Whataroa':
                      case 'Hokitika':
                      case 'Greymouth':
                      case 'Punakaiki':
                        values.cc_email = 'lisa@nzbrochures.com';
                        values.to_name = 'Lisa';
                        break;
                      default:
                        values.to_name = 'Richard';
                    }
                  }
                  const templateParams = {
                    ...values,
                    ordersString,
                    'g-recaptcha-response': captchaKey,
                  };
                  // console.log(templateParams);
                  send(
                    SITE_CONFIG.EMAILJS_serviceID,
                    SITE_CONFIG.EMAILJS_orderForm_templateID,
                    templateParams,
                    SITE_CONFIG.EMAILJS_userID,
                  )
                    .then((response: { status: any; text: any }) => {
                      setSuccessfulSubmission(true);
                      console.log('SUCCESS!', response.status, response.text);
                    })
                    .catch((err: any) => {
                      setErrorSubmission(true);
                      console.log('FAILED...', err);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  dirty,
                  isValid,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Stack spacing={3} alignItems="flex-start">
                      <InputElementControl
                        type="text"
                        name="from_name"
                        label="your name"
                        isRequired
                      />
                      <InputElementControl
                        type="email"
                        name="from_email"
                        label="your email"
                        isRequired
                        // helpText="e.g. name@email.com"
                      />
                      <InputElementControl
                        name="mobilenumber"
                        type="tel"
                        pattern="[0-9]*"
                        label="mobile number"
                        isRequired
                      />
                      <InputElementControl
                        name="region"
                        label="your region"
                        inputType="select"
                        isRequired
                      >
                        {regionList.map((regionName: any) => (
                          <option key={regionName} value={regionName}>
                            {regionName}
                          </option>
                        ))}
                      </InputElementControl>

                      <InputElementControl
                        type="text"
                        name="address"
                        label="postal address"
                        isRequired
                      />
                      <InputElementControl
                        type="number"
                        name="postcode"
                        label="postal code"
                        isRequired
                      />
                      <InputElementControl
                        type="text"
                        name="note"
                        label="note"
                      />
                      {errorSubmission && (
                        <Text color="red.400" fontSize="sm" noOfLines={2}>
                          {errorSubmissionMsg}
                        </Text>
                      )}
                      {!(dirty && isValid && captchaKey !== null) && (
                        <Text color="grey.300" fontSize="sm" noOfLines={2}>
                          ^ Please complete the above fields* in order to submit
                          the form.
                        </Text>
                      )}
                      <ReCAPTCHA
                        sitekey={SITE_CONFIG.RECAPTCHA_SITE_KEY}
                        onChange={setCaptchaKey}
                      />
                      <Divider />
                      <Button
                        type="submit"
                        isLoading={isSubmitting && submitted}
                        disabled={
                          (!(dirty && isValid) ||
                            isSubmitting ||
                            captchaKey === null) &&
                          submitted
                        }
                      >
                        Submit order
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            )}
          </Box>
        </Flex>
      </Container>
    </>
  );
};
