import { Box, Stack } from "@chakra-ui/react";
import { H3, H4, Paragraph as P } from "../theme/ui-components/Typography";
import LIST from "./depots.json";
import InnerPageLayout from "./InnerPageLayout";

interface WarehouseProps {
  regions: string;
  contact: string;
  address: string;
}

const Warehouse = ({ regions, contact, address }: WarehouseProps) => (
  <Box>
    <H3>{regions}</H3>
    <H4>{contact}</H4>
    <P>
      Distribution Delivery Address: <br />
      {address}
    </P>
  </Box>
);

export const Depots = () => (
  <>
    <InnerPageLayout
      title="Warehouse Distribution Depots"
      metaDescrib="Request a Brochure Display Unit from NZBrochures in the Nelson, Marlborough, Christchurch, Canterbury or West Coast regions of New Zealand"
      canonicalUri="warehouse-distribution-depots"
    >
      <Stack spacing={4}>
        {LIST.map((item) => {
          return (
            <Warehouse
              regions={item.regions}
              contact={item.contact}
              address={item.address}
            />
          );
        })}
      </Stack>
    </InnerPageLayout>
  </>
);
