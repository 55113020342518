import { type standing } from './locationList';

export const chchTransport: standing = {
  title: 'Christchurch/Canterbury Transport Circuit',
  region: 'Christchurch Transport',
  category: 'Transport Circuit',
  list: [
'Samuels Vehicle Hire',
'Auto Union Car Rental',
    'Trek Campers',
    'Ferrymead Historic Park',
    'Lyttelton i-site *',
    'Airpark Canterbury',
    'Mighty Campers',
    'Ace Rental Cars',
    'Elements',
    'Ly Lo - Christchurch Airport',
    'Cross Country Rentals',
    'Maugers',
    'Elements car & campervans',
    'Steviez Café Halswall',
    'Bay of Islands Campervans ChCh office',
    'Affordable car rentals',
    'Apex Car Rentals - city',
    'Apex Car Rentals - airport',
    'Arcadia',
    'Avis Car Rental - City',
    'Better Rentals',
    'Budget Campers',
    'Budget Car Rental - City',
    'Camper Co',
    'Canterbury Campas',
    'Christchurch Car Hire',
    'Christchurch Railway Station',
    'Freedom Campers',
    'Go Rentals',
    'Global Vehicle Hire',
    'Handy Rentals',
    'Hertz - City',
    "John Weild's Motor Home Hire",
    'Jucy Rentals - Christchurch Airport',
    'Kia Ora Campers',
    'Kiwi Motorcycle Rentals',
    'Mad Campers',
    'McRent',
    "Mike Pero's Motorcycle Gallery *",
    'Mode Car & Camper Rentals',
    'Moto Car Rentals',
    'Natural High',
    'Discount Car Rentals',
    'New Zealand Rental Cars',
    'NZ4U2U *',
    'Omega Car Rentals - Airport',
    'Orange Rentals',
    'Pegasus Rentals *',
    'Routes Car Rental',
    'Right Car',
    'Roadrunner Rentals',
    'RV Super Centre (Maui, britz, mighty campers)',
    'Scotties',
    'Sunrise',
    'Te Waiponamu Motorcycle Tours',
    'Transport Museum',
    'Travellers Auto Barn',
    'Tui Campers',
    'Yesaway Car Rentals',
    'Abuzzy Rentals',
    'Wendekreisen',
    'Yes Rentals',
    'Rental Cars 24/7',
  ],
};
