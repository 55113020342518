import { Flex } from '@chakra-ui/layout';
import { useState } from 'react';
import misc from '../../misc';
import { ArrowIcon } from '../../theme/ui-components/icons';

export const GoTopButton = () => {
  const [isActive, setActive] = useState(false);
  /* when window scroll執行以下 */
  window.onscroll = () => {
    scrollFunction();
  };
  /* 當可scroll超過300以上才執行以下 */
  const scrollFunction = () => {
    document.documentElement.scrollTop > 300
      ? setActive(true)
      : setActive(false);
  };
  /* 按下go top的按鈕後回到最上層 */
  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <>
      <Flex
        position="fixed"
        display={{ base: 'none', md: 'flex' }}
        bgColor="rgba(0,0,0,0.7)"
        color="white"
        align="center"
        justifyContent="center"
        boxSize={16}
        fontSize="sm"
        zIndex="99"
        cursor="pointer"
        borderRadius="full"
        bottom={{ base: '1.25em', md: '1rem' }}
        right={{ base: '1em', md: '1rem' }}
        opacity={isActive ? 1 : 0}
        border="none"
        transition={misc.transitionAnimate}
        aria-label="Go to top"
        role="button"
        _hover={{
          bgColor: 'black',
        }}
        onClick={() => {
          backToTop();
        }}
      >
        <ArrowIcon boxSize={6} transform="rotate(270deg)" />
      </Flex>
    </>
  );
};
