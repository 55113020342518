import PrintButton from 'theme/ui-components/PrintButton';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { CloseIcon, SearchIcon } from 'theme/ui-components/icons';
import { formatlink } from '../../helpers';
import { STANDINGS, filterByRegion } from './locationList';

export function StandLocationList() {
  // 👇️ store input's value in state for search parameters
  const [searchParams, setSearchParams] = useSearchParams();

  const searchTerm = searchParams.get('searchName') || '';

  function handleSearch(event: any) {
    const searchName = event.target.value;

    if (searchName) {
      setSearchParams({ searchName });
    } else {
      setSearchParams({});
    }
  }

  // 👇️ clear input value
  const handleClearValue = () => setSearchParams({});

  // 👇️ check fucntion for if item includes the search key word
  const isMatchSearchResult = (item: string) =>
    item.toLowerCase().includes(searchTerm.toLowerCase());

  // 👇️ check if search field is empty
  // The useMemo Hook only runs when one of its dependencies update
  const isSearchEmpty = useMemo(
    () => typeof searchTerm === 'string' && searchTerm.length === 0,
    [searchTerm],
  );

  const region = searchParams.get('region');

  const checkRegion = useMemo(() => {
    if (!region) return STANDINGS;
    return filterByRegion(region);
  }, [region]);

  return (
    <>
      <Flex
        gap={{ base: 4, lg: 8 }}
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems={{ lg: 'center' }}
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <InputGroup maxWidth={{ lg: '375px' }}>
          <Input
            type="text"
            name="searchName"
            bgColor="grey.50"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search stand location list"
            aria-label="search standing location list"
          />
          <InputRightElement>
            {isSearchEmpty ? (
              <SearchIcon boxSize={8} />
            ) : (
              <CloseIcon
                boxSize={8}
                cursor="pointer"
                onClick={handleClearValue}
                _hover={{
                  color: 'red.400',
                }}
              />
            )}
          </InputRightElement>
        </InputGroup>
        {isSearchEmpty && (
          <Text fontSize="sm">
            * Due to limited reception areas limited brochure space is available
            at these sites. Therefore, local content is prioritized.
          </Text>
        )}
        <Box width={{ lg: '33%' }} textAlign={{ lg: 'right' }}>
          <PrintButton />
        </Box>
      </Flex>
      <Divider my={2} />
      <Box
        sx={{
          '@media screen': {
            columnCount: [1, 2, 3, 4],
            columnGap: 5,
            gridColumnGap: 5,
          },
        }}
      >
        {checkRegion.map((data) => {
          let category = data.category;
          let title = data.title;
          let list = data.list;
          return (
            <Stack
              key={uuid()}
              aria-label={`location: ${category}`}
              d="inline-block"
              spacing={4}
              mb={12}
              w="full"
              sx={{
                '@media screen': {
                  breakInside: 'avoid',
                  pageBreakInside: 'avoid',
                },
                '@media print': {
                  float: 'left',
                  fontsize: '10pt',
                  width: 'full',
                  color: 'black',
                  mb: '0.5cm',
                  mt: '0.33cm',
                },
              }}
            >
              {title && (
                <Heading
                  as="h3"
                  size="md"
                  id={formatlink(title)}
                  color="red.400"
                  opacity={isSearchEmpty ? 1 : 0.38}
                  textTransform="capitalize"
                >
                  {title}
                </Heading>
              )}
              {/* if not searching */}
              {category && (
                <Heading as="h4" size="sm" opacity={isSearchEmpty ? 1 : 0.38}>
                  {category}
                </Heading>
              )}
              <List
                sx={{
                  'li:nth-of-type(odd)': {
                    bgColor: 'rgba(76, 86, 98, 0.05)',
                    _hover: {
                      bgColor: 'rgba(76, 86, 98, 0.15)',
                    },
                  },
                  //   '@media print': {
                  //     'li:nth-of-type(odd)': {
                  //         bgColor: 'rgba(76, 86, 98, 1)',
                  //       },
                  //   },
                }}
              >
                {list
                  .sort()
                  .filter((name) => isMatchSearchResult(name))
                  .map((name: any) => {
                    const isMatchSearch =
                      !isSearchEmpty && isMatchSearchResult(name);
                    return (
                      <ListItem
                        key={uuid()}
                        lineHeight="1.125"
                        pl={3}
                        py={2}
                        transition="all 0.55s"
                        // bgColor={
                        //   isMatchSearch ? "#fffa64 !important" : "inherit"
                        // }
                        backgroundImage={
                          isMatchSearch
                            ? 'linear-gradient(90deg, rgba(255,247,0, 1) 10%, rgb(250 250 250) 100%)'
                            : 'unset'
                        }
                        borderColor="grey.10"
                        borderBottomWidth={isMatchSearch ? 2 : 0}
                        _hover={{
                          bgColor: 'rgba(76, 86, 98, 0.15)',
                        }}
                        sx={{
                          '@media print': {
                            padding: '0 0.125em 0',
                            float: 'left',
                            display: 'inline-block',
                            _after: {
                              content: '" / "',
                              display: 'inline-block',
                            },
                          },
                        }}
                      >
                        <Text
                          as="span"
                          fontSize={{ base: 'sm' }}
                          fontWeight={isMatchSearch ? 'bold' : 'unset'}
                        >
                          {name}
                        </Text>
                      </ListItem>
                    );
                  })}
              </List>
            </Stack>
          );
        })}
      </Box>
    </>
  );
}
