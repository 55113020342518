const colors = {
  transparent: "transparent",
  grey: {
    10: "#fafafa",
    50: "#F4F4F4",
    100: "#D7D7D7",
    200: "#ababab",
    300: "#878787",
    400: "#757575",
    500: "#4d4d4d",
  },
  deepblue: {
    50: "#F6F8FB",
    100: "#E6E8EB",
    200: "#CDD1D7",
    300: "#6A7587",
    400: "#071937",
    500: "#040F20",
    600: "#040F20",
  },
  black: {
    300: "#4D4D4D",
    400: "#171717",
  },
  red: {
    100: "#FFF4F6",
    400: "#E33D33",
    500: "#D44B42",
  },
  orange: {
    400: "#DF532D",
    500: "#D24620",
  },
  green: {
    400: "#6EC248",
    500: "#2fa84f",
  },
  teal: {
    100: "#EEF4F4",
    200: "#DDEAEA",
    300: "#90D0D0",
    400: "#22a2a2",
    500: "#1E8D8D",
  },
};
const aliases = {
  deepWaterBlue: colors.deepblue[400],
  primary: colors.red[400],
  secondary: colors.deepblue[500],
  tertiary: colors.orange[500],
  success: colors.green[400],
  error: colors.orange[400],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...colors,
  ...aliases,
};
