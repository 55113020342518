import {
  Container,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { H2, Paragraph } from 'theme/ui-components/Typography';

export function NoMatch() {
  return (
    <Container display="flex" gap={6} flexDirection={'column'} maxW="960px">
      <H2>Page Not Found</H2>
      <Paragraph mb={0}>
        The page you were looking for could not be found on our server. This
        might be because:
      </Paragraph>
      <UnorderedList spacing={3}>
        <ListItem>The page has been removed or renamed</ListItem>
        <ListItem>The page no longer exists</ListItem>
        <ListItem>The URL you entered is incorrect</ListItem>
      </UnorderedList>
      <Paragraph mb={0}>
        We apologize for any inconvenience this may have caused. Please try the
        following
      </Paragraph>
      <UnorderedList spacing={3}>
        <ListItem>Check the URL for typos</ListItem>
        <ListItem>
          Use the navigation menu to find what you are looking for
        </ListItem>
        <ListItem>
          Contact our support team if you continue to have problems
        </ListItem>
      </UnorderedList>
      <Paragraph className="lead-paragraph">
        Thank you for your understanding
      </Paragraph>
      <div>
        <Button borderRadius="full">Back to homepage</Button>
      </div>
    </Container>
  );
}
