import { Text, TextProps } from "@chakra-ui/react";

export const Paragraph = (props: TextProps) => (
  <Text
    fontSize={{ lg: "18px" }}
    lineHeight={{ base: "1.5", lg: "1.66" }}
    mb={4}
    {...props}
  />
);

export const Description = (props: TextProps) => (
  <Paragraph
    maxW="960px"
    lineHeight={{ lg: "1.618" }}
    textAlign="center"
    {...props}
  />
);

export const BoldText = (props: TextProps) => (
  <Text fontFamily="enz700" {...props} />
);

export const SmallText = (props: TextProps) => (
  <Text fontSize="sm" lineHeight={{ base: "1.5", lg: "1.8" }} {...props} />
);

export const SmallBoldText = (props: TextProps) => (
  <SmallText fontFamily="enz500" marginBottom={3} {...props} />
);
