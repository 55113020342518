
import MetaHelmet from '../../components/MetaHelmet';
import ContactSection from './ContactSection';

const ContactPage = () => {
  return (
    <>
      <MetaHelmet
        title="Contact Us"
        description="For any inquireis, questions or commendations, please call us based on your region or fill out the following form."
        canonicalUri='contact'
      />
      <ContactSection />
    </>
  );
};

export default ContactPage;
