import { Box, Image, ImageProps, BoxProps } from '@chakra-ui/react';
import { brochureSize } from './BrochureItemProps';

interface FilipCardProps extends BoxProps {
  facing: 'front' | 'back';
  imgSrc: string;
  alt: string;
}
export const FlipCard = ({ facing, alt, imgSrc, ...props }: FilipCardProps) => (
  <Box
    position="absolute"
    overflow="hidden"
    boxSize="full"
    maxW={brochureSize}
    bgColor="rgba(0,0,0,0.38)"
    transition={'transform 0.8s'}
    sx={{
      backfaceVisibility: 'hidden',
    }}
    {...props}
  >
    <picture>
      <source srcSet={`/images/brochures/${imgSrc}.webp`} type="image/webp" />
      <Image
        src={`/images/brochures/${imgSrc}.jpg`}
        alt={alt}
        maxW="unset"
        h="full"
        position="absolute"
        transition="ease-in-out 0.5s;"
        right={facing === 'front' ? 0 : 'unset'}
        left={facing === 'back' ? 0 : 'unset'}
      />
    </picture>
  </Box>
);
