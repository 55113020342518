import {
  Box,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from 'theme/ui-components/icons';
import { Paragraph } from 'theme/ui-components/Typography';
import { BrochureItemProps } from './BrochureItemProps';

interface PopupModalProps extends Omit<ModalProps, 'children'> {
  data: BrochureItemProps;
}


export const PopupModal = ({ data, ...props }: PopupModalProps) => {
  // const colorMode = useColorModeValue('light', 'dark');


  const ModalHeading = () => (
    <Heading as="h1" size="lg" textTransform="capitalize">
      {data.name}
    </Heading>
  );
  return (
    <Modal size="xl" isCentered {...props}>
      <ModalOverlay
        background="rgba(255,255,255,0.87)"
  
        backdropFilter="auto" backdropBlur="4px"
        sx={{
          '.chakra-ui-dark &':{
            background: 'rgba(0,0,0,0.8)',
  
          }
        }}
      />
      <ModalContent bgColor="transparent" boxShadow={0}>
        <ModalCloseButton
          size="lg"
          color="gray.500"
          borderRadius="full"
          // bgColor="white"
          top={{ base: 2, lg: 0 }}
          right={{ base: 2, lg: -10 }}
          backgroundBlendMode="multiply"
          _hover={{
            bgColor: 'grey.100',
          }}
   
        />
        <ModalBody>
          <picture>
            <source
              srcSet={`/images/brochures/${data.imgfile}.webp`}
              type="image/webp"
            />
            <Image
              src={`/images/brochures/${data.imgfile}.jpg`}
              alt={data.name}
              w="full"
            />
          </picture>

          <Stack pt={8} px={{ base: 4, lg: 0 }} spacing={4}>
            {data.website ? (
              <Link
                href={data.website}
                isExternal
                aria-label={`visit ${data.name} website`}
                display="flex"
                alignItems="flex-start"
              >
                <ModalHeading />
                <ExternalLinkIcon boxSize={10} ml={2} mt={-0.5} />
              </Link>
            ) : (
              <ModalHeading />
            )}

            <Stack>
              {data.phone && (
                <Text>
                  Phone:&nbsp;
                  <Link
                    href={`tel:${data.phone}`}
                    aria-label={`call: ${data.phone}`}
                  >
                    {data.phone}
                  </Link>
                </Text>
              )}
              {data.address && <Text>Address: {data.address}</Text>}
            </Stack>
            {data.intro && <Paragraph>{data.intro}</Paragraph>}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
