export const navItems = {
  HOME: [
    {
      title: 'home',
      path: '/',
      active: 'homepage',
    },
  ],
  REGIONS: [
    {
      title: 'nelson airport',
      regionName: 'nelsonAirport',
    },
    {
      title: 'Nelson/Tasman',
      regionName: 'nelson',
      uri: 'nelson',
    },
    {
      title: 'marlborough',
      regionName: 'marlborough',
    },
    {
      title: 'christchurch & canterbury',
      regionName: 'christchurch',
      submenu: [
        {
          title: 'christchurch transport',
        },
      ],
    },
    {
      title: 'christchurch transport',
      regionName: 'chchTransport',
    },
    {
      title: 'west coast',
      regionName: 'westcoast',
    },
  ],
  OTHERS: [
    {
      title: 'stand locations',
    },
    {
      title: 'contact',
    },
  ],
};
