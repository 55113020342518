import { Button, ButtonProps } from "@chakra-ui/react";
// import misc from "../../../misc";

interface PillProps extends ButtonProps {
  active?: boolean;
}

const Pill = ({ active, ...props }: PillProps) => {
  // const colorMode = useColorModeValue("grey.50", "deepblue.400");
  return (
    <Button
      as="span"
      // className={`pill ${active && "active"}`}
      colorScheme="deepblue"
      variant="outline"
      borderRadius="full"
      fontSize={{ base: "xs", lg: "sm" }}
      h="auto"
      py={{ base: 2, lg: 3 }}
      px={{ base: 3, lg: 5 }}
      // mt={{ base: 2, xl: 0 }}
      // mr={2}
      // bgColor={colorMode}
      // transition={misc.transitionAnimate}
      // _hover={{
      //   bgColor: "grey.100",
      // }}
      // sx={{
      //   "&.active": {
      //     color: "white",
      //     bgColor: "deepblue.400",
      //   },
      // }}
      {...props}
    />
  );
};

export default Pill;
